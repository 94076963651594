var timer;
var time = 5000;
var cart = require("../cart/cart");
var util = require("../util");
var dyHelper = require("../dynamicyield/dy");
var base = require("../components/product/base");
var modalResponseHelper = require("../auction/modalResponseHelper");
var dynamicApproachBanner = require("../components/dynamicBannerApproach");

let mincartStates = () => {
    let count = parseInt($("[data-number-qty]").attr("data-number-qty"), 10);
    let $basketIcon = $(".basket-icon");
    if (count >= 1) {
        $(".minicart .minicart-quantity").addClass("d-block");
        if (!$basketIcon.hasClass("basket-icon--active")) {
            $basketIcon.toggleClass("basket-icon--active");
        }
    } else {
        $basketIcon.toggleClass("basket-icon--active");
        $(".minicart .minicart-quantity").toggleClass("d-block");
    }
};

function showMiniCart() {
    var $body = $("body");
    var $minicart = $('.minicart');
    var $popover = $minicart.find(".popover");
    var url = $minicart.data("action-url");
    var isLargeScreen = $(window).width() >= 992;

    if (!$popover.hasClass('show')) {
        if (!Number($minicart.attr('data-update-fpc-cart'))) {
            $popover.addClass("show");
            $body.addClass("show-cart-popover");

            if (isLargeScreen) {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    $popover.removeClass("show");
                }, time);
            }

            fireDySPAEvent();
            return;
        }

        var $placeholder = $('.minicart-placeholder');

        if (Number($placeholder.attr('data-placeholder')) === 0) {
            $popover.empty();
            var $placeholderClone = $placeholder.clone().removeClass('d-none');

            $placeholder.attr('data-placeholder', 1);

            $popover.append($placeholderClone);
        } else {
            var $itemClone = $placeholder.find('.item').clone().addClass('minicart-placeholder');
            $('.product-summary').prepend($itemClone);
        }

        $popover.addClass("show");
        $body.addClass("show-cart-popover");

        $.get(url, function (data) {
            $popover.empty();
            $popover.append(data);
            $minicart.attr('data-update-fpc-cart', 0);
            dynamicApproachBanner();
            fireDySPAEvent();
            var liveTvProductsInMinicart = $(".minicart-main-info .js-live-tv-item");
            var MAXQUANTITY = 10;
            if (liveTvProductsInMinicart.data("maxorderqty") != undefined || liveTvProductsInMinicart.data("maxorderqty") != null) {
                MAXQUANTITY = liveTvProductsInMinicart.data("maxorderqty");
            }

            if (liveTvProductsInMinicart.length > 0) {
                liveTvProductsInMinicart.each(function () {
                    var currentLiveTvProduct = $(this);
                    var currentLiveTvProductID = currentLiveTvProduct.data("id");
                    var currentStock = localStorage.getItem(currentLiveTvProductID);

                    if (currentStock) {
                        var currentQuantitySelector = currentLiveTvProduct.find(".quantity");
                        var actualMaxQuantity = Math.min(currentStock, MAXQUANTITY);

                        var currentOptions = currentQuantitySelector.find("option");

                        currentOptions.each(function (index) {
                            var currentOption = $(this);
                            if (index > actualMaxQuantity - 1) {
                                currentOption.remove();
                            }
                        });
                    }
                });
            }
        });

        if (isLargeScreen) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                $popover.removeClass("show");
                $body.removeClass("show-cart-popover");
            }, time);
        }
    }
}

function fireDySPAEvent() {
    try {
        var productIds = [];
        var len = $(".minicart-main-info .product-summary").find(".product-line-item");
        if (len && len.length > 0 && len.length <= 5) {
            len.each(function () {
                productIds.push({id: $(this).closest(".card").data("id").toString()});
            });
            dyHelper.fireDYEvent("SPA_MINI_CART", productIds);
        }
    } catch (e) {
        console.error("error while calling DY SPA add to Cart event "+e);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getLoginModalHtmlElement() {
    if ($("#loginModal").length !== 0) {
        $("#loginModal").remove();
    }

    var htmlString = "<!-- Modal -->"
    + "<div class=\"modal fade\" id=\"loginModal\" tabindex=\"-1\" role=\"dialog\">"
    + "<span class=\"enter-message sr-only\" ></span>"
    + "<div class=\"modal-dialog login-dialog\">"
    + "<!-- Modal content-->"
    + "<div class=\"modal-content\">"
    + "<div class=\"modal-header\">"
    + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
    + "        <span aria-hidden=\"true\">&times;</span>"
    + "        <span class=\"sr-only\"> </span>"
    + "    </button>"
    + "</div>"
    + "<div class=\"modal-body\"></div>"
    + "<div class=\"modal-footer\"></div>"
    + "</div>"
    + "</div>"
    + "</div>";

    $("body").append(htmlString);
}


/**
 * Do login process by Login popup, if user tries adding product to wishlist
 */
function wishlistLoginProcess() {
    $.spinner().start();
    getLoginModalHtmlElement();
    var url = util.appendToUrl($(".sign-in-menu-container .user a").attr("href"), {format: "ajax", loginFromCart: true});

    $.ajax({
        url: url,
        type: "get",
        dataType: "html",
        success: function (data) {
            if (util.isDataJson(data)) {
                data = JSON.parse(data);
                window.location.href = data.redirectUrl;
            } else {
                var clientSideValidation = require("@sfra/js/components/clientSideValidation");
                $("#loginModal .modal-body").append(data);
                $("#loginModal").modal("show");
                clientSideValidation.invalid();
                $.spinner().stop();
                return;
            }
        },
        error: function error() {
            $.spinner().stop();
        },
    });
}


module.exports = function () {
    cart();

    $(".minicart").on("count:update", function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $(".minicart .minicart-quantity").text(count.quantityTotal);
            $(".minicart .minicart-link").attr({
                "aria-label": count.quantityTotal,
                title: count.quantityTotal,
                "data-number-qty": count.quantityTotal
            });
        }
    });

    $(".minicart-link").on("touchstart click", function (e) {
        e.preventDefault();

        showMiniCart();
    });

    $(".minicart .popover, .minicart .popover-ra").hover(function () {
        clearTimeout(timer);
    });

    $(".minicart .popover, .minicart .popover-ra").mouseleave(function () {
        timer = setTimeout(function () {
            $(".minicart .popover").removeClass("show").css("transition", "all 0.5s ease-in");
            $(".minicart .popover-ra").removeClass("show").css("transition", "all 0.5s ease-in");
        }, time);
    });

    $("body").on("touchstart click", function (e) {
        var $target = $(e.target);

        if ($(".minicart").has($target).length <= 0 || $target.parent(".minicart-close").length > 0 || $target.hasClass("minicart-close")) {
            $(".minicart .popover").removeClass("show").css("transition", "all 0.5s ease-in");
            $(".minicart .popover-ra").removeClass("show").css("transition", "all 0.5s ease-in");
            $("body").removeClass("show-cart-popover");
        }
    });

    $(".minicart").on("dblclick", function (event) {
        if ((event.type === "focusout" && $(".minicart").has(event.target).length > 0)
            || (event.type === "mouseleave" && $(event.target).is(".minicart .quantity"))
            || $("body").hasClass("modal-open")) {
            event.stopPropagation();
            return;
        }
        $(".minicart .popover").removeClass("show").css("transition", "all 0.5s ease-in");
        $(".minicart .popover-ra").removeClass("show").css("transition", "all 0.5s ease-in");
    });

    $("body").on("change", ".minicart .quantity", function () {
        if ($(this).parents(".bonus-product-line-item").length && $(".cart-page").length) {
            location.reload();
        }
    });

    $("body").on("product:afterAddToCart", function () {
        $('.minicart').attr('data-update-fpc-cart', 1)
        mincartStates();
        showMiniCart();
    });

    $("body").on("cart:update", function () {
        $('.minicart').attr('data-update-fpc-cart', 1)
        mincartStates();
    });

    $("body").on("click", ".product-move .move", function (e) {
        e.preventDefault();
        if (!$(".sign-in-menu-container").find(".greeting-item").length) {
            wishlistLoginProcess();

        } else {
            var url = $(this).attr("href");
            var pid = $(this).data("pid");
            var optionId = $(this).closest(".product-info").find(".lineItem-options-values").data("option-id");
            var optionVal = $(this).closest(".product-info").find(".lineItem-options-values").data("value-id");
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $(this).addClass("processing-wishlist");
            $.spinner().start();
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function () {
                    dyHelper.fireDYEvent("ADD_TO_WISHLIST", { pid: pid });
                    displayMessageAndRemoveFromCart();
                },
                error: function () {
                    displayMessageAndRemoveFromCart();
                }
            });
        }
    });

    $(".quantity-form .decrease-quantity-value").on("click", function () {
        var itemId = $(this).data("id");
        var $item = $(".quantity-value-" + itemId);
        var quantityValue = Number($item.val());
        if (quantityValue > $item.attr("min")) {
            quantityValue--;
        }
        $item.val(quantityValue.toString());
    });

    $(".increase-quantity-value").on("click", function () {
        var itemId = $(this).data("id");
        var $item = $(".quantity-value-" + itemId);
        var quantityValue = Number($item.val());
        if (quantityValue < $item.attr("max")) {
            quantityValue++;
        }
        $item.val(quantityValue.toString());
    });

    $("body").on("click", ".minicart .dy-upsell-add-to-cart", function () {
        var addToCartUrl = $(this).data("url");
        var pid = $(this).data("pid");

        var price = $(".product-price[data-pid=" + pid + "]").val().trim().replace(/[$,]/g, "");

        var dyForm = {
            pid: pid,
            quantity: 1,
            price: price
        };

        var form = {
            pid: pid,
            quantity: 1
        };

        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: "POST",
                data: form,
                success: function (data) {
                    $("body").trigger("product:afterAddToCart", data);
                    $("body").trigger("fpcProduct:afterAddToCart", data);
                    $(".minicart").trigger("count:update", data);
                    var minicartUrl = $(".minicart").data("action-url");
                    base.miniCartReportingUrl(data.reportingURL, data.error);
                    dyHelper.fireDYEvent("ADD_TO_CART", dyForm);
                    $.get(minicartUrl, function (data) {
                        $(".minicart .popover").empty();
                        $(".minicart .popover").append(data);
                        fireDySPAEvent();
                        $.spinner().stop();
                    });
                },
                error: function (data) {
                    $.spinner().stop();
                    var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                    if (modal) {
                        $(modal.modalId).modal("show");
                    }
                }
            });
        }
    });
};

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart() {
    $.spinner().stop();
    if ($(".add-to-wishlist-messages").length === 0) {
        $("body").append("<div class=\"add-to-wishlist-messages \"></div>");
    }

    var url = $(".processing-wishlist").data("action");
    $.ajax({
        url: url,
        type: "get",
        data: {
            pid: $(".processing-wishlist").data("pid"),
            uuid: $(".processing-wishlist").data("uuid")
        },
        success: function () {
            util.openModel("", "movedToWishlistSuccess", $(".wishlist-success-msg").html());

            setTimeout(function () {
                location.reload();
            }, 2000);
        },
        error: function (err) {
            if ($(".wishlist-error-msg").length) {
                $(".add-to-wishlist-messages").append("<div class=\"add-to-wishlist-alert text-center alert-danger\">" + err.responseJSON.errorMessage + "</div>");
            }
        }
    });
}
