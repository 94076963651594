require("./settings/lazysettings");
require("lazysizes");
require("lazysizes/plugins/unveilhooks/ls.unveilhooks");
require("slick-carousel");

window.jQuery = window.$ = require("jquery");
var processInclude = require("@sfra/js/util");
var util = require("./util");
require("./thirdParty/jquery.bxslider.min");

$(document).ready(function () {
    processInclude(require("./components/menu"));
    processInclude(require("./components/sticky"));
    processInclude(require("@sfra/js/components/cookie"));
    processInclude(require("./components/consentTracking"));
    processInclude(require("./components/assetModal"));
    processInclude(require("./components/video360Modal"));
    processInclude(require("./components/tooltipPopup"));
    processInclude(require("./components/miniCart"));
    processInclude(require("./components/googleCustomerReview"));
    processInclude(require("./multicart"));
    processInclude(require("./order/order"));
    processInclude(require("./components/search"));
    processInclude(require("./components/footer"));
    processInclude(require("@sfra/js/components/collapsibleItem"));
    processInclude(require("@sfra/js/components/clientSideValidation"));
    processInclude(require("./components/toolTip"));
    processInclude(require("./wishlist/wishlist"));

    $(window).on("scroll", function () {
        var bannerHeight = $(".top-banner-wrapper").height();
        var yScroll = $(window).scrollTop();
        if (yScroll > bannerHeight) {
            $("body").addClass("sticky-top").css("padding-top", bannerHeight);
        }
        else {
            $("body").removeClass("sticky-top");
        }
    });

    if (window.location.search !== "") {
        var queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("clickId") && queryParams.has("utm_campaign")) {
            if (!util.getCookie("clickID")) util.setCookie("clickID", queryParams.get("clickId"), 60);
            if (!util.getCookie("affiliateID")) util.setCookie("affiliateID", queryParams.get("utm_campaign"), 60);
            var date = new Date();
            var cookieDate = date.getDate() + "/"+ (date.getMonth()+1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
            if (!util.getCookie("cookieDate")) util.setCookie("cookieDate", cookieDate, 60);
        }
    }

});

require("./thirdParty/bootstrap");
require("@sfra/js/components/spinner");
require("@sfra/js/components/formFieldFormatters").init();
