var Inputmask = require('inputmask');

/**
 * @description Form field formatters:
 * - phones
 */

function initPhoneFieldFormatters() {
    $('.formatter--phone').each(function() {
        // "don't init twice" guard break
        if (this.inputmask) {
            return;
        }

        Inputmask({
            mask: '(999) 999-9999',
            jitMasking: true
        }).mask(this);
    });
}

function init() {
    initPhoneFieldFormatters();
}

module.exports = {
    init,
    // semantic symlink
    refresh: init,
    initPhoneFieldFormatters
};
