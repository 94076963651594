var dynamicApproach = function () {
    var $currentBasketAmountSelector = $("[data-basketamount]");
    var $approachingLimitSelector = $("[data-approachinglimit]");
    var $approachingFreeShippingProgressSelector = $(".approaching-free-shipping .progress-bar");
    var $containerSelector = $(".approaching-discount");

    var basketAmount = $currentBasketAmountSelector.attr("data-basketamount");
    var approachingLimit = $approachingLimitSelector.attr("data-approachinglimit");
    var appliedFreeShipping = $approachingLimitSelector.attr("data-appliedFreeShipping");
    var appliedPromoFreeShipping = $approachingLimitSelector.attr("data-promofreeshipping") == "true";
    appliedFreeShipping = appliedFreeShipping ? JSON.parse(appliedFreeShipping) : null;
    var percentageToFreeShipping;

    var rangeToPercent = function (number, min, max) {
        return ((number - min) / (max - min));
    };

    percentageToFreeShipping = rangeToPercent(parseInt(basketAmount, 10), 0, parseInt(approachingLimit, 10)) * 100;

    if (percentageToFreeShipping < 100 && !appliedFreeShipping && !appliedPromoFreeShipping) {
        $approachingFreeShippingProgressSelector.attr({
            "style": "width: " + percentageToFreeShipping + "%"
        });
        $containerSelector.addClass("in-progress");
        $(".see-details-modal").hide();
    } else if (percentageToFreeShipping < 100 && !appliedFreeShipping && appliedPromoFreeShipping) {
        $approachingFreeShippingProgressSelector.attr("style", "width:100%");
        $containerSelector.removeClass("in-progress");
        $(".see-details-modal").hide();
    } else {
        $approachingFreeShippingProgressSelector.attr("style", "width:100%");
        $containerSelector.removeClass("in-progress");
        $(".see-details-modal").show();
    }
};

module.exports = dynamicApproach;
