
var base = require("../components/product/base");
var focusHelper = require("base/components/focus");
var handlePostCartAdd = require("../product/cartMessage").handlePostCartAdd;
var dyHelper = require("../dynamicyield/dy");
var gaHelper= require("../tracking/googleanalytics");
var modalResponseHelper = require("../product/modalResponseHelper");
var notifyme= require("../product/notifyme");
var peTracking = require("../tracking/pushEngageTrackingEvent");

/**
 * show toast response
 * @param {Object} res - from the call to set the public status of a list or item in a list
 */
function showResponseMsg(res) {
    $.spinner().stop();
    var status;

    if (res.success) {
        status = "alert-success";
    } else {
        status = "alert-danger";
    }

    if ($(".add-to-wishlist-messages").length === 0) {
        $("body").append(
            "<div class=\"add-to-wishlist-messages \"></div>"
        );
    }

    $(".add-to-wishlist-messages")
        .append("<div class=\"add-to-wishlist-alert text-center " + status + "\">" + res.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
    }, 3000);
}

/**
 * toggles the public / private status of the item or wishlist item
 * @param {string} listID - the order model
 * @param {string} itemID - the customer model
 * @param {Object} callback - function to run if the ajax call returns with an
 *                        error so that the checkbox can be reset to it's original state
 */
function updatePublicStatus(listID, itemID, callback) {
    var url = $("#isPublicList").data("url");
    $.spinner().start();
    $.ajax({
        url: url,
        type: "post",
        dataType: "json",
        data: {
            listID: listID,
            itemID: itemID
        },
        success: function (data) {
            if (callback && !data.success) {
                callback();
            }
            showResponseMsg(data);
        },
        error: function (err) {
            if (callback) {
                callback();
            }
            showResponseMsg(err);
        }
    });
}
/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove item from wishlist failed
 */
function displayErrorMessage($elementAppendTo, msg) {
    if ($(".remove-from-wishlist-messages").length === 0) {
        $elementAppendTo.append(
            "<div class=\"remove-from-wishlist-messages \"></div>"
        );
    }
    $(".remove-from-wishlist-messages")
        .append("<div class=\"remove-from-wishlist-alert text-center alert-danger\">" + msg + "</div>");

    setTimeout(function () {
        $(".remove-from-wishlist-messages").remove();
    }, 3000);
}

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $(".wishlistItemCardsData").data("public-view");
    var listUUID = $(".wishlistItemCardsData").data("uuid");
    var url = $(".wishlistItemCardsData").data("href");
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: "get",
        data: {
            pageNumber: newPageNumber + 1,
            publicView: publicView,
            id: listUUID
        }
    }).done(function (data) {
        $(".wishlistItemCards").empty();
        $("body .wishlistItemCards").append(data);

        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(function () {
        $(".more-wl-items").remove();
    });
    $.spinner().stop();
}

$(document).off("click", ".wishlistItemCards .number-spinner button").on("click", ".wishlistItemCards .number-spinner button", function () {
    var btn = $(this);
    var inputCtrl = btn.closest(".number-spinner").find("input");
    var oldValue = inputCtrl.val().trim();
    var newVal = 0;
    var maxVal = inputCtrl.attr("max");
    if (btn.attr("data-dir") == "up") {
        if (parseInt(oldValue, 10) < parseInt(maxVal, 10)) {
            newVal = parseInt(oldValue, 10) + 1;
        } else {
            return false;
        }
    } else {
        if (oldValue > 1) {
            newVal = parseInt(oldValue, 10) - 1;
        } else {
            newVal = 1;
        }
    }
    inputCtrl.val(newVal);
});

$(".wishlistItemCards .card.product-info").hover(
    function () {
        $(this).find(".wlQuickView").removeClass("visibilityhidden");
    },
    function () {
        $(this).find(".wlQuickView").addClass("visibilityhidden");
    }
);

module.exports = {
    submitNotifyMeOnWishlist: notifyme.notifyMeInit(),
    removeFromWishlist: function () {
        $("body").off("click", ".remove-from-wishlist").on("click", ".remove-from-wishlist", function (e) {
            e.preventDefault();
            var url = $(this).data("url");
            var button = $(this);
            var elMyAccount = $(".account-wishlist-item").length;

            // If user is in my account page, call removeWishlistAccount() end point, re-render wishlist cards
            if (elMyAccount > 0) {
                $(".wishlist-account-card").spinner().start();
                $.ajax({
                    url: url,
                    type: "get",
                    dataType: "html",
                    data: {},
                    success: function (html) {
                        $(".wishlist-account-card>.card").remove();
                        $(".wishlist-account-card").append(html);
                        $(".wishlist-account-card").spinner().stop();
                    },
                    error: function () {
                        var $elToAppend = $(".wishlist-account-card");
                        $elToAppend.spinner().stop();
                        var msg = $elToAppend.data("error-msg");
                        displayErrorMessage($elToAppend, msg);
                    }
                });
                // else user is in wishlist landing page, call removeProduct() end point, then remove this card
            } else {
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: "get",
                    dataType: "json",
                    data: {},
                    success: function () {
                        $.spinner().stop();
                        $(button).parents(".card").remove();
                        $("#wishlist-removed-popup").modal("show");
                        setTimeout(function () {
                            $("#wishlist-removed-popup").modal("hide");
                            window.onbeforeunload = function () {
                                window.scrollTo(0, 0);
                            };
                            window.location.reload();
                        }, 1500);
                    },
                    error: function () {
                        $.spinner().stop();
                        var $elToAppendWL = $(".wishlistItemCards");
                        var msg = $elToAppendWL.data("error-msg");
                        displayErrorMessage($elToAppendWL, msg);
                    }
                });
            }
        });

        $("body").on("click", ".remove-allfrom-wishlist-btn", function (e) {
            e.preventDefault();
            var url = $(this).data("url");
            var elMyAccount = $(".account-wishlist-item").length; // If user is in my account page, call removeWishlistAccount() end point, re-render wishlist cards

            if (elMyAccount > 0) {
                $(".wishlist-account-card").spinner().start();
                $.ajax({
                    url: url,
                    type: "get",
                    dataType: "html",
                    data: {},
                    success: function success(html) {
                        $(".wishlist-account-card>.card").remove();
                        $(".wishlist-account-card").append(html);
                        $(".wishlist-account-card").spinner().stop();
                    },
                    error: function error() {
                        var $elToAppend = $(".wishlist-account-card");
                        $elToAppend.spinner().stop();
                        var msg = $elToAppend.data("error-msg");
                        displayErrorMessage($elToAppend, msg);
                    }
                }); // else user is in wishlist landing page, call removeProduct() end point, then remove this card
            } else {
                $.spinner().start();
                var publicView = $(".wishlistItemCardsData").data("public-view");
                var listUUID = $(".wishlistItemCardsData").data("uuid");
                $.ajax({
                    url: url,
                    type: "get",
                    dataType: "html",
                    data: {
                        publicView: publicView,
                        id: listUUID
                    },
                    success: function success() {
                        location.reload();
                    },
                    error: function error() {
                        var $elToAppend = $(".wishlistItemCards");
                        $elToAppend.spinner().stop();
                        var msg = $elToAppend.data("error-msg");
                        displayErrorMessage($elToAppend, msg);
                    }
                }).done(function () {
                    $.spinner().stop();
                });
            }
        });
    },
    
    focusEditWishlistProductModal: function () {
        $("body").on("shown.bs.modal", "#editWishlistProductModal", function () {
            $("#editWishlistProductModal").siblings().attr("aria-hidden", "true");
            $("#editWishlistProductModal .close").focus();
        });
    },

    onClosingEditWishlistProductModal: function () {
        $("body").on("hidden.bs.modal", "#editWishlistProductModal", function () {
            $("#editWishlistProductModal").remove();
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");

            $("#editWishlistProductModal").siblings().attr("aria-hidden", "false");
        });
    },
    onClosingRemoveWishlistProductModal: function () {
        $("body").on("hidden.bs.modal", "#removeWishlistItem, #variantSelectionErrorModal", function () {
            $(".live-tv-shop-last-section").removeClass("remove-wishlist-css");
        });
    },

    trapEditWishlistProductModalFocus: function () {
        $("body").on("keydown", "#editWishlistProductModal", function (e) {
            var focusParams = {
                event: e,
                containerSelector: "#editWishlistProductModal",
                firstElementSelector: ".close",
                lastElementSelector: ".btn-update-wishlist-product",
                nextToLastElementSelector: ".select-size"
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    updateWishlistUpdateButton: function () {
        $("body").on("product:updateAddToCart", function (e, response) {
            response.$productContainer.find(".btn-update-wishlist-product").attr("disabled", !response.product.readyToOrder || !response.product.available);
        });
    },

    updateWishlistNotification: function () {
        $("body").on("click", "input[name='turnOffWishlist']", function () {
            var notify = $("input[name='turnOffWishlist']").is(":checked");
            var action = $(this).data("url") + `&notify=${notify}`;
            $.ajax({
                url: action,
                type: "get"
            });
        });
    },

    updateWishListItem: function () {
        $("body").on("click", ".btn-update-wishlist-product", function (e) {
            e.preventDefault();

            var updateButtonBlock = $(this).closest(".wishlist-item-update-button-block").find(".update-wishlist-url");
            var updateProductUrl = updateButtonBlock.val();
            var uuid = updateButtonBlock.data("uuid");

            var form = {
                uuid: uuid,
                pid: base.getPidValue($(this))
            };

            $("#editWishlistProductModal").spinner().start();

            $.ajax({
                url: updateProductUrl,
                type: "post",
                context: this,
                data: form,
                dataType: "json",
                success: function () {
                    $.spinner().start();
                    $("#editWishlistProductModal").spinner().stop();
                    $("#editWishlistProductModal").remove();
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    var pageNumber = $(".wishlistItemCardsData").data("page-number") - 1;
                    renderNewPageOfItems(pageNumber, false, ".product-info .edit-add-to-wishlist .edit:first");
                },
                error: function () {
                    var msg = $(".btn-update-wishlist-product").data("error-msg");

                    $("#editWishlistProductModal").spinner().stop();
                    $("#editWishlistProductModal").remove();
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");

                    if ($(".update-wishlist-messages").length === 0) {
                        $("body").append(
                            "<div class=\"update-wishlist-messages \"></div>"
                        );
                    }

                    $(".update-wishlist-messages")
                        .append("<div class=\"update-wishlist-alert text-center alert-danger\">" + msg + "</div>");

                    setTimeout(function () {
                        $(".update-wishlist-messages").remove();
                    }, 5000);
                }
            });
        });
    },

    toggleWishlistStatus: function () {
        $("#isPublicList").on("click", function () {
            var listID = $("#isPublicList").data("id");
            updatePublicStatus(listID, null, null);
        });
    },

    toggleWishlistItemStatus: function () {
        $("body").on("click", ".wishlist-item-checkbox", function () {
            var itemID = $(this).closest(".wishlist-hide").find(".custom-control-input").data("id");
            var el = $(this).siblings("input");
            var resetCheckBox = function () {
                return el.prop("checked") ?
                    el.prop("checked", false) :
                    el.prop("checked", true);
            };

            updatePublicStatus(null, itemID, resetCheckBox);
        });
    },

    addToCartFromWishlist: function () {
        $("body").on("click", ".add-to-cart-wishList .add-to-cart", function (e) {
            e.stopPropagation();
            var pid;
            var addToCartUrl;
            var pidsQty;

            $("body").trigger("product:beforeAddToCart", this);

            pid = $(this).data("pid");
            addToCartUrl = $(this).data("url");
            pidsQty = parseInt($(this).closest(".product-info").find(".quantity").val(), 10);
            var price;
            if ($(this).closest(".product-info").find(".js-formatted-price").html().includes("&nbsp;$")) {
                price = $(this).closest(".product-info").find(".js-formatted-price").html().replace("&nbsp;$", "");
            } else {
                price = $(this).closest(".product-info").find(".sales .js-formatted-price").html().trim().replace(/[$,]/g, "");
            }
            var dyForm = {
                pid: pid,
                quantity: pidsQty,
                price: price
            };
            var form = {
                pid: pid,
                quantity: pidsQty
            };

            var gaForm = {
                pid: "" + pid,
                quantity: "" + pidsQty,
                category: $(this).closest(".product-info").find(".thumbnail-wrapper a").attr("pcategory"),
                price: price.replace("NOW:", "").trim(),
                name: $(this).closest(".product-info").find(".thumbnail-wrapper a").attr("pname")
            };
            var atcSource = $(this).closest(".product-info").find(".thumbnail-wrapper a").attr("sourcebuy");

            var prodDetObjForPE = $(this).closest(".add-to-cart-wishList").data("productdetails");
            if ((typeof prodDetObjForPE === "string" || prodDetObjForPE instanceof String) && prodDetObjForPE != "") {
                prodDetObjForPE = JSON.parse(prodDetObjForPE);
            }
            //alert expiry time current time + 30 days
            var currentdate = new Date();
            currentdate.setDate(currentdate.getDate() + 30);
            var expiryisodate = currentdate.toISOString();
            var pePDAObj = {
                productid: prodDetObjForPE ? prodDetObjForPE.id : "",
                link: prodDetObjForPE ? prodDetObjForPE.pdpurl : "",
                price: prodDetObjForPE ? prodDetObjForPE.listprice : "",
                alertprice: prodDetObjForPE ? prodDetObjForPE.salesprice : "",
                expiryisodate: expiryisodate,
                availability: "inStock",
                variantid: prodDetObjForPE ? prodDetObjForPE.id : "",
                title: prodDetObjForPE ? prodDetObjForPE.name : "",
                imageurl: $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "",
                bigimageurl: prodDetObjForPE ? prodDetObjForPE.imageurl : ""
            };

            if ($(this).data("option")) {
                form.options = JSON.stringify($(this).data("option"));
            }

            $(this).trigger("updateAddToCartFormData", form);
            if (addToCartUrl) {
                $.spinner().start();
                $.ajax({
                    url: addToCartUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $("body").trigger("product:afterAddToCart", data);
                        $("body").trigger("fpcProduct:afterAddToCart", data);
                        $.spinner().stop();
                        base.miniCartReportingUrl(data.reportingURL, data.error);
                        dyHelper.fireDYEvent("ADD_TO_CART", dyForm);
                        gaHelper.fireGAEvent("PLP_ADD_TO_CART", gaForm, atcSource);
                        if (pePDAObj.link !== "") {
                            peTracking.pushEngageEvent("PRICE_DROP_ALERT", pePDAObj);
                        }
                    },
                    error: function (data) {
                        $.spinner().stop();
                        var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                        if (modal) {
                            $(modal.modalId).modal("show");
                        }
                    }
                });
            }
        });
    },
    moreWLItems: function () {
        $("body").on("click", ".more-wl-items", function () {
            var pageNumber = $(".wishlistItemCardsData").data("page-number");
            renderNewPageOfItems(pageNumber, true);
        });
    },
    copyWishlistLink: function () {
        $("body").on("click", ".fa-link", function () {
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val($("#shareUrl").val()).select();
            document.execCommand("copy");
            $temp.remove();
            $(".copy-link-message").removeClass("d-none");
            setTimeout(function () {
                $(".copy-link-message").addClass("d-none");
            }, 3000);
        });
    },
    submitWishlistSearch: function () {
        $("body").on("click", "#wishlist-search button", function (e) {
            var firstName = $("#wishlist-search-first-name").val();
            var lastName = $("#wishlist-search-last-name").val();
            var email = $("#wishlist-search-email").val();

            if ((!firstName && !lastName && !email) ||
                (firstName && !lastName && !email) ||
                (!firstName && lastName && !email)) {
                e.preventDefault();
                $(".wishlist-error-search div").addClass("alert alert-danger");
                var errorText = $(".wishlist-error-search").data("error-msg");
                $(".wishlist-error-search div").html(errorText);
            }
        });
    },
    moreWLSearchResults: function () {
        $("body").on("click", ".more-wl-results", function () {
            var firstName = $(this).data("search-fname");
            var lastName = $(this).data("search-lname");
            var pageNumber = $(this).data("page-number");
            var uuids = [];
            $(".wl-hit").each(function () {
                uuids.push($(this).find("a").data("id"));
            });
            var url = $(this).data("url");
            $.spinner().start();
            $.ajax({
                url: url,
                method: "POST",
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    uuids: JSON.stringify(uuids),
                    pageNumber: pageNumber + 1
                },
                success: function (data) {
                    if (data.results.changedList) {
                        $(".wl-hits .wl-hit").remove();
                    }
                    $("#result-count").html(data.results.totalString);
                    data.results.hits.forEach(function (hit) {
                        var divString = "<div class=\"row wl-hit\">" +
                            "<div class=\"text-left col-6\">" +
                            hit.firstName + " " + hit.lastName +
                            "</div>" +
                            "<div class=\"text-right col-6\">" +
                            "<a href=\"" + hit.url + "\" title=\"" + hit.urlTitle + "\" data-id=\"" + hit.id + "\">" +
                            hit.urlText +
                            "</a>" +
                            "</div>" +
                            "</div>";
                        $(".wl-hits").append(divString);
                    });
                    if (data.results.showMore) {
                        $(".find-another-wl .more-wl-results").data("page-number", data.results.pageNumber);
                    } else {
                        $(".find-another-wl .more-wl-results").remove();
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    initPagination: function () {
        $("body").on("click", ".pagination-page:not(.current-page)", function (e) {
            $(e.target).find("a")[0].click();
        });
    }
};
