var scrollAnimate = require("@sfra/js/components/scrollAnimate");
var debounce = require("lodash/debounce");
var util = require("../util");
var dyHelper = require("../dynamicyield/dy");
var processInclude = require("base/util");
processInclude(require("../channelFinder"));
var lastScrollTop = 0;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = "alert-success";
        $("#emailsubscribetextid").val("");
    } else {
        status = "alert-danger";
    }

    if ($(".email-signup-message").length === 0) {
        $("body").append(
            "<div class=\"email-signup-message\"></div>"
        );
    }
    $(".email-signup-message")
        .append("<div class=\"email-signup-alert text-center margin-top " + status + "\">" + data.msg + "</div>");

    setTimeout(function () { // eslint-disable-line
        $(".email-signup-message").remove();
        button.removeAttr("disabled");
    }, 3000);
}

/**
 * Show footer links on large breakpoints which are collapced on mobile
 */

function updateFooterLinks() {
    if ($(window).width() >= 769) {
        $("#footerLinks .js-footer-links-body").addClass("desktop show");
    } else {
        $("#footerLinks .js-footer-links-body.desktop").removeClass("show desktop");
    }
}

/** This function is used to hide back-to-top button when header is displayed, and show it when client is scrolling down.*/
function updateBackToTop() {
    var $header = $(".header").height();
    var scrollTop = $(window).scrollTop();
    var $backToTop = $(".back-to-top");

    if (scrollTop > lastScrollTop || scrollTop < $header) {
        if (!$backToTop.hasClass("d-none")) {
            $backToTop.addClass("d-none");
        }
    } else {
        if ($backToTop.hasClass("d-none")) {
            $backToTop.removeClass("d-none");
        }
    }
    lastScrollTop = scrollTop;
}

function displayTrackOrderLink() {
    if ($(".footer-track-order").length && $(".sign-in-menu-container .greeting-item").length) {
        $(".footer-track-order").addClass("d-none");
    } else {
        $(".footer-track-order").removeClass("d-none");
    }
}

/**
 * Checks if the email value entered is correct format
 * @param {string} email - email string to check if valid
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,}$/;
    return regex.test(email);
}

module.exports = function () {
    $(window).on("scroll", updateBackToTop);

    $(".back-to-top").click(function () {
        scrollAnimate();
    });

    $(document).on("click", ".subscribe-email", function (e) {
        e.preventDefault();
        var url = $(this).data("href");
        var button = $(this);
        var $form = $(this).closest("form");
        var emailId = $form.find("input[name=hpEmailSignUp]").val();
        var canadianResident = $form.find("input[name=hpCanadianResident]").prop("checked");
        if (validateEmail(emailId)) {
            $.spinner().start();
            $(this).attr("disabled", true);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: {
                    emailId: emailId,
                    canadianResident: canadianResident
                },
                success: function (data) {
                    var dyForm = {
                        email: $form.find("input[name=hpEmailSignUp]").val()
                    };
                    dyHelper.fireDYEvent("NEWSLETTER_SUBSCRIPTION", dyForm);
                    if ($("#newsletterModel").length > 0 && data.success) {
                        util.setCookie("newsletter", true, 30);
                        $("#newsletterModel").modal("hide");
                    }
                    displayMessage(data, button);
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        } else {
            var errorMsg = $("#emailsubscribetextiderrormesg").val();
            var data = {
                success : false,
                msg : errorMsg,
                action : "EmailSubscribe-Subscribe",
                error : true
            };
            displayMessage(data, button);
        }
    });

    $(window).on("load", function () {
        var fbenabled= $("#facebookURLPageView").data("isenabled");
        if (fbenabled) {
            var getIPApiUrl = $("#getIPApiUrl").val();
            var fbcUrl= $("#facebookURLPageView").val();
            var fbp = util.getCookie("_fbp");
            var fbc = util.getCookie("_fbc");
            var fbcData = {
                client_ip_address: $("#customerIpAddress").val(),
                event_source_url: window.location.href,
                client_user_agent: window.navigator.userAgent,
                fbp: fbp,
                fbc: fbc
            };
            if (fbcData.client_ip_address == "null" || fbcData.client_ip_address == null) {
                $.ajax({
                    url: getIPApiUrl,
                    method: "GET",
                    dataType: "json",
                    success: function (data) {
                        fbcData.client_ip_address = data.ip;
                        util.sendFBEvent(fbcUrl, fbcData);
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
            } else {
                util.sendFBEvent(fbcUrl, fbcData);
            }
        }
    });


    $(document).on("click", ".subscribe-sms", function (e) {
        e.preventDefault();
        var url = $(this).data("href");
        var button = $(this);
        var $form = $(this).closest("form");
        var phoneNumber = $form.find("input[name=smsSignUp]").val();
        var acceptTerms = $form.find("input[name=acceptTerms]").prop("checked");
        $.spinner().start();
        $(this).attr("disabled", true);
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: {
                phoneNumber: phoneNumber,
                acceptTerms: acceptTerms
            },
            success: function (data) {
                var dyForm = {
                    phoneNumber: $form.find("input[name=smsSignUp]").val()
                };
                dyHelper.fireDYEvent("NEWSLETTER_SUBSCRIPTION", dyForm);
                if (data.success) {
                    $form.find("input[name=smsSignUp]").val("");
                }
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    // commenting newsletter popup trigger based on cookie
    /*if (!util.getCookie("newsletter")) {
        $.ajax({
            url: resources.urls.newsletterPopup,
            type: "get",
            dataType: "html",
            success: function (data) {
                util.openModel("", "newsletterModel", data);
                $("#newsletterModel").on("hide.bs.modal", function () {
                    util.setCookie("newsletter", true, 30);
                });
            },
            error: function () {
                location.reload();
            }
        });
    }*/

    updateFooterLinks();

    $(window).on("resize", debounce(function () {
        updateFooterLinks();
    }, 500));

    displayTrackOrderLink();

    function togglePasswordField(elementId, iconClass) {
        var passwordField = document.getElementById(elementId);
        var passwordToggleIcon = document.querySelector(iconClass);

        if (passwordField.type === "password") {
            passwordField.type = "text";
            passwordToggleIcon.classList.remove("fa-eye-slash");
            passwordToggleIcon.classList.add("fa-eye");
        } else {
            passwordField.type = "password";
            passwordToggleIcon.classList.remove("fa-eye");
            passwordToggleIcon.classList.add("fa-eye-slash");
        }
    }

    $("body").on("click", "#fastSecureView", function () {
        togglePasswordField("fast-login-form-password", ".fast-password-eye-icon");
    });

    $("body").on("click", "#secureView", function () {
        togglePasswordField("login-form-password", ".password-eye-icon");
    });

    $("body").on("click", "#secureView-register", function () {
        togglePasswordField("registration-form-password", ".reg-password-eye-icon");
    });

};
