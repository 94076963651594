var loaderInstance = require("../../../../../../int_paypal/cartridge/client/default/js/loader");
var $loaderContainer = document.querySelector(".paypalLoader");
var loader = loaderInstance($loaderContainer);
const defaultStyle = {
    color: "gold",
    shape: "rect",
    layout: "vertical",
    tagline: false
};

/**
 *  Appends error message on cart page
 *
 * @param {string} message error message
 */
function showCartErrorHtml(message) {
    $(".checkout-btn").addClass("disabled");
    $(".cart-error").append(
        `<div class="alert alert-danger alert-dismissible valid-cart-error fade show cartError" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            ${message}
        </div>`
    );
    window.scrollTo(0, 0);
}

/**
 *  Gets purchase units object, creates order and returns object with data
 *
 * @param {Object} _ - arg
 * @param {Object} actions - paypal actions
 * @returns {Object} with purchase units data and application context
 */
function createOrder(_, actions) {
    loader.show();
    return $
        .get(window.paypalUrls.getCartPurchaseUnit)
        .then(function ({ purchase_units }) {
            let parsedPurchaseUnit = JSON.parse(purchase_units[0].amount.value);
            if (parsedPurchaseUnit === 0) {
                showCartErrorHtml("You must have at least 1 product selected.");
            }
            const application_context = {
                shipping_preference: "GET_FROM_FILE"
            };
            loader.hide();
            return actions.order.create({
                purchase_units,
                application_context
            });
        });
}

/**
 *  Makes post call and transfers order ID to returnFromCart endpoint, goes to checkout place order stage
 *
 * @param {Object} orderID - order id
 */
function onApprove({ orderID }) {
    $.ajax({
        type: "POST",
        url: window.paypalUrls.returnFromCart,
        contentType: "application/json",
        data: JSON.stringify({
            paypalOrderID: orderID
        }),
        success: () => {
            loader.hide();
            window.location.href = window.paypalUrls.placeOrderStage;
        },
        error: function () {
            if (!document.querySelector(".cartError")) {
                showCartErrorHtml("An internal server error has occurred. \r\nRetry the request later.");
            }
            loader.hide();
        }
    });
}
/**
 * Hides loader on paypal widget closing without errors
 *
 */
function onCancel() {
    loader.hide();
}
/**
 * Shows errors if paypal widget was closed with errors
 *
 */
function onError() {
    loader.hide();
}
/**
 *Inits paypal button on cart page
 */
function initPaypalButton() {
    if  ($(".cart.cart-page").length > 0 && window.paypal) {
        loader.show();
        window.paypal.Buttons({
            createOrder,
            onApprove,
            onCancel,
            onError,
            style: defaultStyle
        }).render(".paypal-cart-button")
            .then(() => {
                loader.hide();
            });
    }
}

module.exports = function () {
    initPaypalButton();
};
