module.exports = function () {
    $(document).on("input:active", function (event) {
        var $this = $(event.target);
        var $formGroup = $this.parents(".form-group");

        if ($this.val().length > 0) {
            $formGroup.addClass("active");
        } else {
            $formGroup.removeClass("active");
        }     
    });
    

    $(document).on("input", ".input-custom-orange", function (event) {
        $(this).trigger("input:active", event);
    });

    $(".input-custom-orange").trigger("input:active");
};
