var modalHelper = require("../auction/modalHelper");

module.exports = {
    showPDPBidMessage: function (message) {
        var $bidInfoMessage =  $("#bid-info-message");
        $bidInfoMessage.find(".bid-info-message-text").text(message);
        $bidInfoMessage.removeClass("d-none");
    },

    generateErrorModal: function (responseObject) {
        var generatedModal = null;
        switch (responseObject.errorCode) {
            case 1:
                generatedModal = modalHelper.generateMaxBidErrorModal(responseObject.modalTitle, responseObject.errorMessage, responseObject.amount, responseObject.toSendedBidObjectModal);
                break;
            case 2:
                generatedModal= modalHelper.generateBidModal(responseObject.modalTitle, responseObject.errorMessage, responseObject.amount, responseObject.toSendedBidObjectModal);
                break;
            case 3:
                generatedModal = modalHelper.generateMessageModal(responseObject.modalTitle, responseObject.errorMessage);
                break;
            case 4:
                generatedModal = modalHelper.generatePayNowModal(responseObject.modalTitle, responseObject.errorMessage);
                break;
            case 5:
                generatedModal = this.showPDPBidMessage(responseObject.pdpMessage);
                break;
            case 6:
                generatedModal = modalHelper.generateMaxProductListModal(responseObject.errorMessage, responseObject.redirectUrl);
                break;
            case 7:
                generatedModal = modalHelper.generateBidNowSucessModel(responseObject.modalTitle, responseObject.errorMessage, responseObject.redirectUrl);
                break;
            default: break;
        }
        return generatedModal;
    },

    generateMaxBidModal: function (responseObject) {
        var generatedModal;
        var isBidEqualToMaxBid;

        if (responseObject.amount) {
            isBidEqualToMaxBid = true;
            generatedModal = modalHelper.generateMaxBidModal(responseObject.object.customerName, responseObject.amount, responseObject.toSendedBidObjectModal, responseObject.object.bidData, isBidEqualToMaxBid);
        } else {
            isBidEqualToMaxBid = false;
            generatedModal = modalHelper.generateMaxBidModal(responseObject.object.customerName, responseObject.object.highestBid, responseObject.toSendedBidObjectModal, responseObject.object.bidData, isBidEqualToMaxBid);
        }

        return generatedModal;
    }
};
