module.exports = {
    generateMessageModal: function (title, message) {
        var $messageModal = $("#messageModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }
        var htmlString = "<!-- Modal -->"
        + "<div class=\"modal message-modal fade\" id=\"messageModal\" tabindex=\"0\" role=\"dialog\">"
        + "<div class=\"modal-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        +      "<span class=\"header-title\">" + title + "</span>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">x</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\">" + message
        +       "<div class=\"row\"><div class=\"col-12\"><button class='close btn' data-dismiss=\"modal\">OK</button></div></div> </div>"
        + "</div>"
        + "</div>"
        + "</div>";
        $("body").append(htmlString);
    },

    generateFormModal: function (title, content) {
        var $formModal = $("#formModal");
        if ($formModal.length !== 0) {
            $formModal.remove();
        }
        var htmlString = "<!-- Modal -->"
        + "<div class=\"modal form-modal fade\" id=\"formModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<div class=\"modal-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        +      "<span class=\"header-title\">" + title + "</span>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\">" 
        +       content
        + "</div>"
        + "</div>"
        + "</div>";
        $("body").append(htmlString);
    },

    generateFormModalNoTitle: function (content) {
        var $formModal = $("#modalNoTitle");
        if ($formModal.length !== 0) {
            $formModal.remove();
        }
        var htmlString = "<!-- Modal -->"
        + "<div class=\"modal form-modal fade\" id=\"modalNoTitle\" tabindex=\"1\" role=\"dialog\">"
        + "<div class=\"modal-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\">"
        + "<div class=\"modal-body-text\">" 
        +       content
        + "</div>"
        + "</div>"
        + "</div>"
        + "</div>";
        $("body").append(htmlString);
    },

    openAddressModal: function (title, left, right, message) {
        var $formModal = $("#confirmAddress");
        if ($formModal.length !== 0) {
            $formModal.remove();
        }
        var suggectionMsg = message ?  message : "";
        var htmlString = "<!-- Modal -->" +
            "<div class=\"modal fade mt-5\" id=\"confirmAddress\" tabindex=\"-1\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
            "<!-- Modal content-->" +
            "<div class=\"modal-content\">" +
            "<div class=\"modal-header\">" +
            "<span class=\"header-title\">" + title + "</span>" +
            "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
            "<span aria-hidden=\"true\"> &times; </span>" +
            "</button>" +
            "</div>" +
            suggectionMsg +
            "<div class=\"address-content\">" +
            left +
            right +
            "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
        $("body").find("#confirmAddress").remove();
        $("body").append(htmlString);
    },

    isJson: function (object) {
        try {
            JSON.parse(object);
        } catch (e) {
            return false;
        }
        return true;
    }
};
