var debounce = require("lodash/debounce");

var $stickyEl = $(".sticky-scroll");
var $stickyScroll = $(".sticky-buttons");
$(window).on("scroll resize", debounce(function () {
    var isPositionFixed = $stickyEl.hasClass("is-sticky");
    if ($(this).scrollTop() > 200 && !isPositionFixed) {
        $stickyEl.addClass("is-sticky");
        $stickyScroll.addClass("is-sticky-buttons");
    }
    if ($(this).scrollTop() < 200 && isPositionFixed) {
        $stickyEl.removeClass("is-sticky");
        $stickyScroll.removeClass("is-sticky-buttons");
    }
}, 20));

if ($(window).width() < 544) {
    $(window).on("scroll", debounce(function () {
        var $el = $(".fixed-elem");
        var isPositionFixed = ($el.css("position") == "fixed");
        if ($(this).scrollTop() > 200 && !isPositionFixed) {
            $el.css({
                "position": "fixed",
                "top": "0px"
            });
            $el.addClass("is-sticky");
        }
        if ($(this).scrollTop() < 200 && isPositionFixed) {
            $el.css({
                "position": "static",
                "top": "0px"
            });
            $el.removeClass("is-sticky");
        }
    }, 20));
}
