module.exports = function () {
    let tap = "ontouchstart" in document.documentElement;
    let dropdownHook = () => {
        $(".inside-lc-menu-container").on("mouseenter mouseleave", function (e) {
            e.preventDefault();
            $(this).find(".dropdown-menu").toggle();
            $("body").toggleClass("show-itemlist-dropdown");
        });
        $(".inside-lc-menu-container .js-trigger-dropdown").on("touchstart", function (e) {
            if (e.cancelable) {
                e.preventDefault();
            }
            $(".inside-lc-menu-container").find(".dropdown-menu").toggle();
            $(".hover-dropdown").find(".dropdown-menu").hide();
            $(".js-trigger-dropdown").toggleClass("mini-menu-link--active");
        });
    };
    dropdownHook();
    const hoverDropdown = () => {
        $(document).on("mouseenter mouseleave", ".hover-dropdown", function (e) {
            if (!tap) {
                e.preventDefault();
                $(this).find(".dropdown-menu").toggle();
                $("body").toggleClass("show-itemlist-dropdown");
            }
        });

        $(document).on("touchstart", ".hover-dropdown a.acc-link, .hover-dropdown .user-message", function (e) {
            e.preventDefault();
            $(".inside-lc-menu-container").find(".dropdown-menu").hide();
            $(".hover-dropdown").find(".dropdown-menu").toggle();
            $("body").toggleClass("show-itemlist-dropdown");
        });
    };
    hoverDropdown();
};
