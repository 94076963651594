
var focusHelper = require("@sfra/js/components/focus");

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$(".tracking-consent").data("caonline")) {
        return;
    }

    var urlContent = $(".tracking-consent").data("url");
    var urlAccept = $(".tracking-consent").data("accept");
    var urlReject = $(".tracking-consent").data("reject");
    var textYes = $(".tracking-consent").data("accepttext");
    var textNo = $(".tracking-consent").data("rejecttext");

    var htmlString = "<!-- Modal -->"
        + "<div class=\" show tracking-consent-start d-none\" id=\"consent-tracking\" aria-modal=\"true\" role=\"dialog\" style=\"display: block;\">"
        + "<div class=\"modal-dialog fixed-bottom tracking-consent-model\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content tracking-consent-content\">"
        + "<div class =\"row\">"
        + "<div class=\"col-12 col-sm-8 col-md-9 modal-body-cookie tracking-consent-body\"></div>"       
        + "<div class=\"col-12 col-sm-4 col-md-3 button-wrapper\">"
        + "<button class=\"affirm btn btn-primary continue-button\" data-url=\"" + urlAccept + "\" autofocus data-dismiss=\"modal\">"
        + textYes
        + "</button>"
        + "<button type=\"button\" class=\"decline close-button\" data-url=\"" + urlReject + "\" data-dismiss=\"modal\" >"
        + textNo
        + "</button>"
        + "</div>"
        + "</div>"        
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);

    $.ajax({
        url: urlContent,
        type: "get",
        dataType: "html",
        success: function (response) {
            $(".modal-body-cookie").html(response);

            if ($(".page").attr("data-action")=="LiveTv-Show") {
                $(".tracking-consent-model").addClass("bottom-consent");
            }

            $(".tracking-consent-start").removeClass("d-none");
        },
        error: function () {
            $("#consent-tracking").remove();
        }
    });

    $("#consent-tracking .button-wrapper button").click(function (e) {
        e.preventDefault();
        var url = $(this).data("url");
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function () {
                $("#consent-tracking").remove(); 
            },
            error: function () {
                $("#consent-tracking").remove();
            }
        });
    });
}


module.exports = function () {
    if ($(".consented").length === 0 && $(".tracking-consent").hasClass("api-true")) {
        showConsentModal();
    }

    if ($(".tracking-consent").hasClass("api-true")) {
        $(".tracking-consent").click(function () {
            showConsentModal();
        });
    }

    $("body").on("shown.bs.modal", "#consent-tracking", function () {
        $("#consent-tracking").siblings().attr("aria-hidden", "true");
        $("#consent-tracking .close").focus();
    });

    $("body").on("hidden.bs.modal", "#consent-tracking", function () {
        $("#consent-tracking").siblings().attr("aria-hidden", "false");
    });

    $("body").on("keydown", "#consent-tracking", function (e) {
        var focusParams = {
            event: e,
            containerSelector: "#consent-tracking",
            firstElementSelector: ".affirm",
            lastElementSelector: ".decline",
            nextToLastElementSelector: ".affirm"
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};
