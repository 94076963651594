
module.exports = function () {
    $("body").on("shown.bs.modal", "#video360Modal", function (e) {
        $(this).find(".video-360").attr("src", $(e.relatedTarget).attr("href"));
    });
    $("body").on("hidden.bs.modal", "#video360Modal", function () {
        $("video").trigger("pause");
    });

    $("body").on("shown.bs.modal", "#video360ModalSirv2", function () {
        $(this).find(".video-360-second").attr("src", $(".js-show-video-360-second").attr("href"));
    });
    $("body").on("hidden.bs.modal", "#video360ModalSirv2", function () {
        $("video").trigger("pause");
    });
};
