var total = 0;
module.exports = function () {
    $(document).on("click", ".select-auction", function () {
        this.dataset.selected = this.dataset.selected === "true" ? "false" : "true";
        var amount = this.dataset.amount && this.dataset.amount !== "null" ? Number(this.dataset.amount) : 0;
        if (this.dataset.selected === "true") {
            total += amount;
            $(this).addClass("btn-primary");
            $(".purchase-selected").attr("data-selected", "true");
        } else {
            total -= amount;
            $(this).removeClass("btn-primary");
        }
        if (!$(".select-auction").hasClass("btn-primary")) {
            $(".purchase-selected").attr("data-selected", "false");
        }

        $("#cartTotalAmount").html(total);
    }); 

    $(document).on("click", ".purchase-all", function () {
        var url = $(this).data("url");
        window.location.href = url;
    }); 

    $(document).on("click", ".purchase-selected", function () {
        var url = $(this).data("url");
        var checkUrl = $(this).data("checkUrl");
        checkAndSendCheckboxValues(checkUrl, url);
    }); 

    $(document).on("change", ".auctions-per-page", function () {
        window.location.href = $(this).val();
    });

    $(document).on("click", ".bid-table-header a.sort-trigger", function (e) {
        e.preventDefault();
        var $sortTrigger = $(this),
            sortFilter = $sortTrigger.attr("data-field"),
            isSortOrderAsc = ($sortTrigger.attr("data-sort-order") !== "desc"),
            $bidItems = $(".bid-table-item[data-" + sortFilter + "]");

        $(".bid-table-header a.sort-trigger").removeAttr("data-sort-order");
        $sortTrigger.attr("data-sort-order", isSortOrderAsc ? "desc" : "asc");

        $bidItems.sort(function (a, b) {
            var x = isSortOrderAsc ? a.dataset[sortFilter] : b.dataset[sortFilter];
            var y = isSortOrderAsc ? b.dataset[sortFilter] : a.dataset[sortFilter];
            if (sortFilter === "orderstatus") {
                y = isSortOrderAsc ? a.dataset[sortFilter] : b.dataset[sortFilter];
                x = isSortOrderAsc ? b.dataset[sortFilter] : a.dataset[sortFilter];
                if (x === "Expired") {
                    x = "expired";
                }
                if (y === "Expired") {
                    y = "expired";
                }
            }

            if (sortFilter === "maxbid") {
                if (x === "-") {
                    x = "0";
                }
                if (y === "-") {
                    y = "0";
                }
                x = Number(x);
                y = Number(y);
            }
            return (sortFilter !== "bidamount" && sortFilter !== "currentbid") ? (x > y) - (x < y) : parseFloat(x, 10) - parseFloat(y, 10);
        }).map(function (i, bidItem) {
            $(bidItem).css("order", i + 1);
            return $(bidItem);
        });
    });

    $(document).on("change", ".bid-table-header select.bid-status-sort-trigger", function (e) {
        e.preventDefault();
        var $sortTrigger = $(this),
            sortFilter = $sortTrigger.attr("data-field"),
            $bidItems = $(".bid-table-item[data-" + sortFilter + "]"),
            sortFilterVal = $sortTrigger.val().toLowerCase();

        $(".bid-table-header a.sort-trigger").removeAttr("data-sort-order");
        $bidItems.map(function () {
            var $bidItem = $(this);
            $bidItem.css("order", 0);
            if (sortFilterVal === "default") {
                $bidItem.addClass("d-flex").removeClass("d-none");
            } else {
                var bidItemVal = $bidItem.attr("data-" + sortFilter).toLowerCase();
                $bidItem.toggleClass("d-flex", (bidItemVal == sortFilterVal)).toggleClass("d-none", (bidItemVal != sortFilterVal));
            }
            return $bidItem;
        });
    });

};

/**
 * Search selected checkbox values
 * and return an array with selected items uuid
 * @returns {Array} selectedItems
 */
function getSelectedItems() {
    var selectedItems = [];
    $(".select-auction[data-selected='true'").each(function () {
        selectedItems.push($(this).data("id"));
    });
    return selectedItems;
}

/**
 * send ajax to Cart-CheckAuctionItem
 * and update price and button values with
 * response data
 */
function checkAndSendCheckboxValues(checkUrl, redirectUrl) {
    var selectedItems = getSelectedItems();
    if (!selectedItems || selectedItems.length === 0) return;
    $.ajax({
        url: checkUrl,
        type: "POST",
        data: {selectedItems},
        success: function () {
            window.location.href = redirectUrl;
        }
    });
}
