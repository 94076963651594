module.exports = function () {
    $(document).on("click", ".js-content-asset-modal", function (e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.data("id") ;
        var assetID = $this.data("asset") ? $this.data("asset") : "";
        getModalHtmlElement(assetID);
        fillModalElement(url);
    });  
};

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(assetID) {
    var $assetModal = $("#assetModal");
    if ($assetModal.length !== 0) {
        $assetModal.remove();
    }
    var htmlString = "<!-- Modal -->"
        + "<div class=\"modal asset-modal " + assetID + " fade\" id=\"assetModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<div class=\"modal-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        +      "<span class=\"header-title\"></span>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\"></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
}

/**
 * replaces the content in the modal window with provided content asset content.
 * @param {string} url - url to Page-Show request
 */

function fillModalElement(url) {
    $(".asset-modal .modal-body").spinner().start();
    $.ajax({
        url: url,
        method: "GET",
        dataType: "html",
        success: function (data) {
            var parsedHtml = parseHtml(data);
            var $assteModalBody =  $("#assetModal .modal-body");
            
            $assteModalBody.empty();
            $assteModalBody.html(parsedHtml.body);
            $("#assetModal .sr-only").text(parsedHtml.closeButton);
            $("#assetModal .header-title").text(parsedHtml.header);
            $("#assetModal").modal("show");
            $("body").trigger("assetModal:ready");
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and header of the modal window
 *
 * @return {Object} - Object with properties body and header.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));
  
    var $body = $html.find(".content-text");
    var $header = $html.find(".content-header");
    var $closeButton = $html.find(".close-window");

    return { body: $body.html(), header: $header.text(), closeButton: $closeButton.text() };
}
