/* eslint-disable */

import { generateMessageModal } from "./checkout/modalHelper";

$(document).on('click', '.chat-support', function() {
	if ($('.uiButton').length) {
	   $(".uiButton").click();
	}
});

function showErrorMsg(inputZip) {
    var errorMsgZip ="<div class= 'error-content'>"+
    "<div class =' missing-pin-message'> Unfortunately we do not have any available channels for your zipcode - " + inputZip + "</div>" +
    "<div class = 'trouble-text'> Having trouble finding ShopLC on TV?</div>" + 
    "<button class=' chat-support'>Chat with Support</button></div>";
    $("#channelfinder").append(errorMsgZip);
    $.spinner().stop();
}

function validateZipAndReturnCountry(inputZip) {
    var regexUS = "^\\d{5}$";
    var regexCA = "^[A-Za-z][0-9][A-Za-z][\\s]?[0-9][A-Za-z][0-9]$";

    var patternUS = new RegExp(regexUS);
    var patternCA = new RegExp(regexCA);

    if (patternUS.test(inputZip)) {
        return "USA";
    } else if (patternCA.test(inputZip)) {
        return "CAN";
    } else {
        return "INVALID_PIN_COUNTRY";
    }
}

export default function () {
    $(document).on("click", ".channel-finder-button", function () {
        $.spinner().start();
        var inputZip = $("#zccZip").val();
        var country = validateZipAndReturnCountry(inputZip);
        if ($("#channelfinder").length > 0) {
            $("#channelfinder").remove();
        }
        var channelfinderdiv = "<div id='channelfinder'></div>";
        $("#maincontent").append(channelfinderdiv);

        if (country === "INVALID_PIN_COUNTRY") {
            showErrorMsg(inputZip);
        } else {            
            var url = $(this).attr("href") + "?zipCodeName=" + inputZip;

            $.ajax({
                type: "GET",
                url: url,
                success: function (data) {
                    if (data.channelDetails != "") {

						var header = "<div class='zcc-head'><span class='zcc-zip'>" + inputZip +
							"</span><span class='zcc-stn'> - Shop LC HD</span></div>";
						
						$("#channelfinder").append(header);
                        $("#channelfinder").append(data.channelDetails);                        
                    } else {
                        showErrorMsg(inputZip);
                    }
                    $.spinner().stop();
                },
                error: function (data) {
                    generateMessageModal("Error", "Something went wrong");
                    $("#messageModal").modal("show");
                    $.spinner().stop();
                }
            });
        }
    });
};
