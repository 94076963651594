
module.exports = function () {
    setTimeout(function () {
        var $googleReview = $(".googleReview");
        if ($googleReview.length) {
            var apiUrl = $googleReview.data("apiurl");
            var merchantId = $googleReview.data("merchantid");
            var position = $googleReview.data("position");
            var language = $googleReview.data("language");
            
            $googleReview.remove();
            $.getScript(apiUrl);

            window.renderBadge = function () {
                var ratingBadgeContainer = document.createElement("div");
                document.body.appendChild(ratingBadgeContainer);
                window.gapi.load("ratingbadge", function () {
                    window.gapi.ratingbadge.render(
                        ratingBadgeContainer, {
                            "merchant_id": merchantId,
                            "position": position
                        });
                });
            };
            window.___gcfg = {
                lang: language
            };
        }
    }, 10000);
    
};
