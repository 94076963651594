module.exports = function () {
    $(document).on("click", ".contentAssettooltip", function () {
        var $alert = $(this).siblings(".alert.tooltip-popup");
        $alert.addClass("show");
        $alert.removeClass("d-none");
    });

    $(document).on("click", ".budget-pay-button", function () {
        var $parent = $(this).parent();
        $parent.removeClass("show");
        $parent.addClass("d-none");
        $("body").removeClass("close-modal");
    });

    var isClicked = false;
    $(document).on("click", ".specifications .icon-display", function () {
        if ($(".popover").hasClass("show")) {
            $("body").addClass("activePopover");
        } else {
            $("body").removeClass("activePopover");
        }
        var iconTarget = $(this).data("icon");
        var iconContent = $("#productSpecificationsTooltips").find(".tooltip-key[data-icon-key=\"" + iconTarget + "\"]");
        isClicked = true;
        if (iconContent.length >= 0) {
            $(this).popover({
                html: true,
                title: iconTarget,
                content: iconContent[0],
                placement: "bottom",
            });
            $(this).popover("show");
        }
    });
    $(document).on("mouseenter", ".specifications .icon-display", function () {
        isClicked = false;
        var iconTarget = $(this).data("icon");
        var iconContent = $("#productSpecificationsTooltips").find(".tooltip-key[data-icon-key=\"" + iconTarget + "\"]");
        if (iconContent.length >= 0) {
            $(this).popover({
                html: true,
                title: iconTarget,
                content: iconContent[0],
                placement: "bottom",
            });
            $(this).popover("show");
        }
        $(document).on("mouseout", ".specifications .icon-display", function (e) {
            if (isClicked == true) {
                return;
            }
            var container = $(".popover");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.popover("hide");
            } else {
                container.popover("show");
            }
        });
    });

    $(".payment-info-popover[data-toggle=\"popover\"]").popover();
    $(".info-popover").on("click", function () {
        $(".info-popover").not(this).popover("hide");

        $(document).on("mouseup", function (e) {
            var container = $(".popover");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.popover("hide");
            }
        });
    });

    $(document).on("click", function (e) {
        $(".payment-info-popover[data-toggle=\"popover\"],[data-original-title]").each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
                (($(this).popover("hide").data("bs.popover") || {}).inState || {}).click = false; // fix for BS 3.3.6
            }
        });
    });

    $(".payment-info-popover[data-toggle=\"popover\"]").on("click", function (evt) {
        var elem,
            delay;
        delay = 10000; // 10 seconds
        evt.preventDefault();
        evt.stopImmediatePropagation();
        elem = evt.currentTarget;

        if ($(elem).data("popoverisvisible") == 1) {
            $(elem).popover("hide");
            $(elem).data("popoverisvisible", 0);
        } else {
            $(elem).popover("show");
            $(elem).data("popoverisvisible", 1);
            setTimeout(function () {
                if ($(elem).data("popoverisvisible") == 1) {
                    $(elem).popover("hide");
                    $(elem).data("popoverisvisible", 0);
                }
            }, delay);
        }
    });
};
