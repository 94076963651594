module.exports = {
    //Enable/disable submit button for form based on values for fields
    checkRequiredFields: function (container, button) {
        var requiredFieldQty = 0;
        var $requiredFormGroup = container.find(".form-group.required");
        var $requiredFormFields = $requiredFormGroup.find(".form-control");

        $requiredFormFields.removeClass("filled");

        $requiredFormFields.each(function () {
            if (
                $(".billing-radio:checked").val() === "false" ||
                ($(".billing-radio:checked").val() === "true" && $(this).parents(".billing-address").length == 0)
            ) {
                requiredFieldQty++;
                if (this.value) {
                    $(this).addClass("filled");
                } else {
                    $(this).removeClass("filled");
                }

                if (this.id == "checkoutGuestEmail" && $(this.closest(".form-group")).find(".response-error").text().length > 1) {
                    $(this).removeClass("filled");
                }
            }
        });

        var isValid = $requiredFormGroup.find(".form-control.filled").length == requiredFieldQty;

        if (button) {
            if (isValid) {
                button.removeClass("disabled");
            } else {
                button.addClass("disabled");
            }
        }

        return isValid;
    }
};
