/* eslint-disable no-useless-escape */

var base = require("../components/product/base");
var focusHelper = require("@sfra/js/components/focus");
var dynamicApproachBanner = require("../components/dynamicBannerApproach");
var clientSideValidation = require("@sfra/js/components/clientSideValidation");
var bidHistory = require("../components/bidHistory");
var formHelper = require("../checkout/formHelper");
var util = require("../util");
var gtmHelper = require("../gtm/gtm");
var gaHelper = require("../tracking/googleanalytics");
var dyHelper = require("../dynamicyield/dy");
var initPaypalButton = require("./initPaypalCartButton");
var modalResponseHelper = require("../product/modalResponseHelper");
var handlePostCartAdd = require("../product/cartMessage").handlePostCartAdd;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
        return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        gtmHelper.fireGtmEvent("Error", {
            errorMessage: data.valid.message ? data.valid.message : data.resources.emptyCartMsg
        });

        if (data.valid.message) {
            $(".validation-error").remove();
            var errorHtml = "<div class=\"alert alert-danger validation-error alert-dismissible valid-cart-error " +
                "fade show\" role=\"alert\">" +
                "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
                "<span aria-hidden=\"true\">&times;</span>" +
                "</button>" + data.valid.message + "</div>";

            $(".cart-error").append(errorHtml);
        } else {
            $(".cart").empty().append("<div class=\"row\"> " +
                "<div class=\"col-12 text-center\"> " +
                "<h1>" + data.resources.emptyCartMsg + "</h1> " +
                "</div> " +
                "</div>"
            );
            $(".number-of-items").empty().append(data.resources.numberOfItems);
            $(".minicart-quantity").empty().append(data.numItems);
            $(".minicart-link").attr({
                "aria-label": data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $(".minicart .popover").empty();
            $(".minicart .popover").removeClass("show").css("transition", "all 0.5s ease-in");
        }

        $(".checkout-btn").addClass("disabled");

    } else {
        $(".validation-error").remove();
        $(".checkout-btn").removeClass("disabled");
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $(".number-of-items").empty().append(data.resources.numberOfItems);
    // remove shipping discount check to apply partial discount on order
    // removed data.totals.shippingLevelDiscountTotal.value != 0

    // Rendering cart-items html.
    if (data.htmlText) {
        let parser = new DOMParser();
        const doc = parser.parseFromString(data.htmlText, "text/html");
        var bonusProduct = doc.getElementsByClassName("free-gift-product");
        if (bonusProduct.length != 0 && $(".free-gift-product").length == 0) {
            $(".checkout-block-items-container").append(bonusProduct);
        } else if (bonusProduct.length == 0) {
            $(".free-gift-product").remove();
        }
    }

    //Added logic to compare shipping cost without currency sign
    var totalShippingCost = data.totals.totalShippingCost;
    totalShippingCost = totalShippingCost.replace(/[^0-9\.-]+/g, "");

    if (totalShippingCost === "0.0" || totalShippingCost === "0.00" || totalShippingCost === "0") {
        $(".shipping-cost").empty().append(data.resources.freeShipping);
    } else {
        $(".shipping-cost").empty().append(data.totals.totalShippingCost);
    }
    var stickyBtnTotal = data.fullPayableAmount && data.atLeastOneBudgetPay ? "$" + data.fullPayableAmount : data.totals.grandTotal;
    $(".shipping-total-cost").empty().append(data.totals.totalShippingCost);
    $(".tax-total").empty().append(data.totals.totalTax);
    $(".grand-total").empty().append(data.totals.grandTotal);
    $(".grand-total-input").val(data.totals.grandTotal);
    $(".sub-total, .mobile-subtotal-blk #subtotalval").empty().append(data.totals.subTotal);
    if (data.hasBonusProduct) {
        $(".minicart-subtotal .sub-total").empty().append("$" + data.adjustedMerchandizeTotalPrice);
    }
    $(".next-step-buttons-container .price-total").text(stickyBtnTotal);

    //added next lines
    $(".estimated-savings").empty().append(data.estimatedSavings);
    $(".js-full-payable-amount").empty().append(data.fullPayableAmount);
    $("#payableAmount").empty().append(data.fullPayableAmount);
    $(".normalproducts-quantity").empty().append(`(${data.normalProductsQuantity})`);
    $(".raisingauction-quantity").empty().append(`(${data.auctionProductsQuantity})`);

    var $amzRadioButton = $(".amazon-radio-button");
    var $amzMessage = $(".amazon-not-eligible");
    if (data.hasCBDProduct) {
        $amzMessage.removeClass("d-none");
        if (!$amzRadioButton.hasClass("d-none")) {
            $amzRadioButton.addClass("d-none");
        }
    } else {
        $amzRadioButton.removeClass("d-none");
        if (!$amzMessage.hasClass("d-none")) {
            $amzMessage.addClass("d-none");
        }
    }

    var $paymentContainer = $(".checkout-block.checkout-payment-container");
    var $placeOrderButton = $(".place-order-btn");
    if (data.isStorecreditFullyCovered) {
        //hide payment & enable button
        if (!$paymentContainer.hasClass("d-none")) {
            $paymentContainer.addClass("d-none");
        }
        if ($placeOrderButton.hasClass("disabled")) {
            $placeOrderButton.addClass("disabled");
        }

    } else {
        //show payment & disable button
        if ($paymentContainer.hasClass("d-none")) {
            $paymentContainer.removeClass("d-none");
        }
        formHelper.checkRequiredFields($(".checkout-container"));
    }
    var isFedexEnabled = $(".shipping-options-wrapper").attr("data-is-fedex-enabled");

    if (data.shippingCountry) {
        $("#shippingCountryValue").val(data.shippingCountry);
        var $message = $(".upsMessage");
        var searchPattern = isFedexEnabled ? new RegExp("^" + "fedex-expedited", "i") : new RegExp("^" + "ups-2nd", "i");
        var UPSShipping = searchPattern.test(data.shipments[0].selectedShippingMethod);
        if (data.shippingCountry === "CA" || !UPSShipping) {
            $message.each(function () {
                if (!$(this).hasClass("d-none")) {
                    $(this).addClass("d-none");
                }
            });
        } else {
            $message.each(function () {
                if ($(this).hasClass("d-none")) {
                    $(this).removeClass("d-none");
                }
            });
        }
    }

    $(".minicart-quantity").empty().append(data.numItems);

    $(".minicart-link").attr({
        "aria-label": data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    //ShpLC4749

    var budgetPayCheckboxStatus = false;
    if (data && data.items.length > 0) {
        for (var i = 0; i < data.items.length; i += 1) {
            if (data.items[i].selectedBudgetPay === true) {
                budgetPayCheckboxStatus = data.items[i].selectedBudgetPay;
                break;
            }
        }
    }

    if (budgetPayCheckboxStatus == true) {
        $(".budgetpay-message").removeClass("d-none");//remove budgetpay message
        $("#paypal-content").addClass("d-none");
    } else {
        $(".budgetpay-message").addClass("d-none");
        $("#paypal-content").removeClass("d-none");
    }

    if (data.totals.totalDiscounts != "$0.00") {
        $(".order-discount-total").empty().append(data.totals.totalDiscounts);
        if (($(".leading-lines.order-discount").hasClass("d-none"))) {
            $(".leading-lines.order-discount").removeClass("d-none");
        }
        if (!($(".order-discount-label").hasClass("red"))) {
            $(".order-discount-label").addClass("red");
        }
        if (!($(".order-discount-total").hasClass("red"))) {
            $(".order-discount-total").addClass("red");
        }
    } else {
        $(".order-discount-total").empty().append(data.totals.totalDiscounts);
        if (!($(".leading-lines.order-discount").hasClass("d-none"))) {
            $(".leading-lines.order-discount").addClass("d-none");
        }
        $(".order-discount-label").removeClass("red");
        $(".order-discount-total").removeClass("red");
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $(".shipping-discount").removeClass("hide-shipping-discount");
        $(".shipping-discount-total").empty().append("- " +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $(".shipping-discount").addClass("hide-shipping-discount");
    }

    if (data.totals.usedCustomerStoreCredits != "$0.00") {
        $(".totals-store-credit .store-credit-value").html(data.totals.usedCustomerStoreCredits);
        if (($(".leading-lines.totals-store-credit").hasClass("d-none"))) {
            $(".leading-lines.totals-store-credit").removeClass("d-none");
        }
    } else {
        $(".totals-store-credit .store-credit-value").html(data.totals.usedCustomerStoreCredits);
        if (!($(".leading-lines.totals-store-credit").hasClass("d-none"))) {
            $(".leading-lines.totals-store-credit").addClass("d-none");
        }
    }

    //summary quantity
    if (data.items) {
        $(".summary-product-quantity, .mobile-subtotal-blk .subtotal-quantity").text(data.items.length);
    }

    data.items.forEach(function (item) {
        var $shippingItemWithUUID = $(".item-shipping-all-" + item.UUID);
        var freeShippingLabel = $shippingItemWithUUID.data("free-label");
        var shippingCountry = $("#shippingCountryValue").val();
        // Removing logic to apply free shipping partial item instead of all items
        // if (data.totals.shippingLevelDiscountTotal.value !== 0) {
        //     $shippingItemWithUUID.find(".currency-symbol").hide();
        //     $(".item-shipping-" + item.UUID).empty().text(freeShippingLabel);
        // }
        if (item.shippingPrice || (shippingCountry === "CA" && item.shippingPrice === 0)) {
            $(".item-shipping-" + item.UUID).empty().append(item.shippingPrice);
            $shippingItemWithUUID.find(".currency-symbol").show();
        } else {
            $shippingItemWithUUID.find(".currency-symbol").hide();
            $(".item-shipping-" + item.UUID).empty().text(freeShippingLabel);
        }
        if (item.renderedPromotions) {
            $(".item-" + item.UUID).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $(".item-total-" + item.UUID).empty().append(item.priceTotal.renderedPrice);
            $(".line-item-total-" + item.UUID).empty().append(item.priceTotal.price);
            // $(".product-info.uuid-" + item.UUID + " .js-formatted-price").text(item.priceTotal.nonAdjustedPrice);
        }
        //added statement
        if (item.installmentAmount && item.installmentsNumber) {
            $(".item-installments-amount-" + item.UUID).empty().append(item.installmentAmount);
            $(".item-installments-number-" + item.UUID).empty().append(item.installmentsNumber);
            $(".item-one-payment-" + item.UUID).empty().append(item.priceTotal.price);
            $("input:radio[name=\"budgetPay-" + item.UUID +"\"]").filter("[value=\"notSelected\"]").prop("checked", !item.selectedBudgetPay);
            $("input:radio[name=\"budgetPay-" + item.UUID +"\"]").filter("[value=\"selected\"]").prop("checked", item.selectedBudgetPay);
            $(".js-bp-mobile-select[data-uuid=" + item.UUID +"]").val(item.selectedBudgetPay ? "multiEntry" : "singleEntry")
        }

        var promotionContainer = $(".js-promotion-callout-" + item.UUID);

        if (promotionContainer.length) {
            promotionContainer.empty();
            var appliedCallout = promotionContainer.data("appliedCallout");

            item.appliedPromotions.forEach(function (appliedPromotion) {
                promotionContainer.append(`<div>
                <i class=\"fa fa-check-circle tick\"></i>
                <span>
                <b>${appliedCallout.replace("{0}", appliedPromotion.discount)}</b>
                </span>
                </div>`);
            });
            item.availablePromotions.forEach(function (availablePromotion) {
                promotionContainer.append(`<div>
                <i class="fa fa-times-circle promotion-callout-not-applied-icon"></i>
                <span>
                ${availablePromotion.calloutMsg}
                </span>
                </div>`);
            });
        }

        //free shipping
        if (item.freeShipping) {
            $(".d-free-shipping-discount-" + item.UUID).empty().html(`
            <i class=\"fa fa-check-circle tick\"></i>
            <b>Free Shipping Applied!</b>
            `);
        }
        else {
            $(".d-free-shipping-discount-" + item.UUID).html("");
        }
    });

    if (data.hasBonusProduct) {
        $(".order-discount-total").empty()
            .append(data.totals.totalDiscounts);
        $(".order-discount-label").addClass("red");
        $(".order-discount-total").addClass("red");
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    $(".error-notification").remove();
    var errorHtml = "<div class=\"alert alert-danger alert-dismissible error-notification valid-cart-error " +
        "fade show\" role=\"alert\">" +
        "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
        "<span aria-hidden=\"true\">&times;</span>" +
        "</button>" + message + "</div>";

    $(".cart-error").append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = "";
    $(".approaching-discounts").empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += "<div class=\"single-approaching-discount text-center\">"
                + item.discountMsg + "</div>";
        });
    }

    $(".approaching-discounts").append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = "";

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }
    if (lineItem) {
        $(".availability-" + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += "<p class=\"line-item-attributes\">" + message + "</p>";
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += "<p class=\"line-item-attributes line-item-instock-date\">"
                    + lineItem.availability.inStockDate
                    + "</p>";
            }
        }

        $(".availability-" + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === "color";
        });

        if (colorAttr) {
            var colorSelector = ".Color-" + uuid;
            var newColor = "Color: " + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === "size";
        });

        if (sizeAttr) {
            var sizeSelector = ".Size-" + uuid;
            var newSize = "Size: " + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = ".card.product-info.uuid-" + uuid + " .item-image > img";
        $(imageSelector).attr("src", lineItem.images.small[0].url);
        $(imageSelector).attr("alt", lineItem.images.small[0].alt);
        $(imageSelector).attr("title", lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = ".lineItem-options-values[data-option-id=\"" + option.optionId + "\"]";
        $(optSelector).data("value-id", option.selectedValueId);
        $(optSelector + " .line-item-attributes").text(option.displayName);
    }

    var qtySelector = ".quantity[data-uuid=\"" + uuid + "\"]";
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data("pid", data.newProductId);

    $(".remove-product[data-uuid=\"" + uuid + "\"]").data("pid", data.newProductId);

    var priceSelector = ".line-item-price-" + uuid + " .sales .value";
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr("content", lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = ".line-item-price-" + uuid + " .list .value";
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr("content", lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($("#editProductModal").length !== 0) {
        $("#editProductModal").remove();
    }
    var htmlString = "<!-- Modal -->"
        + "<div class=\"modal fade\" id=\"editProductModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<span class=\"enter-message sr-only\" ></span>"
        + "<div class=\"modal-dialog quick-view-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\"></div>"
        + "<div class=\"modal-footer\"></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".product-quickview");
    var footer = $html.find(".modal-footer").children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $(".modal-body").spinner().start();
    $.ajax({
        url: editProductUrl,
        method: "GET",
        dataType: "json",
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $("#editProductModal .modal-body").empty();
            $("#editProductModal .modal-body").html(parsedHtml.body);
            $("#editProductModal .modal-footer").html(parsedHtml.footer);
            $("#editProductModal .modal-header .close .sr-only").text(data.closeButtonText);
            $("#editProductModal .enter-message").text(data.enterDialogMessage);
            $("#editProductModal").modal("show");
            $("body").trigger("editproductmodal:ready");
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $(".cart-delete-confirmation-btn");
    var $productToRemoveSpan = $(".product-to-remove");

    $deleteConfirmBtn.data("pid", productID);
    $deleteConfirmBtn.data("action", actionUrl);
    $deleteConfirmBtn.data("uuid", uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Cleans basket remplate
 * @param {object} data
 */
function cleanBasket(data) {
    if ($(".cart-page").length > 0 || $("#checkout-main").length > 0) {
        if (data.basket.items.length === 0 && data.basket.redirectToCartUrl) {
            window.location.href = data.basket.redirectToCartUrl;
        }
    }

    $(".number-of-items").empty().append(data.basket.resources.numberOfItems);
    $(".minicart-quantity").empty().append(data.basket.numItems);

    $(".minicart-link").attr({
        "aria-label": data.basket.resources.minicartCountOfItems,
        title: data.basket.resources.minicartCountOfItems,
        "data-number-qty": data.basket.numItems
    });
    $(".minicart .popover").empty();
    $(".minicart .popover").removeClass("show").css("transition", "all 0.5s ease-in");
    $("body").removeClass("modal-open");
    $("html").removeClass("veiled");
}

/**
 * Updates basket totals and validates it
 * @param {object} data
 * @param {string} uuid
 */
function updateCartValues(data, uuid) {
    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
            $(".uuid-" + data.toBeDeletedUUIDs[i]).remove();
        }
    }

    $(".uuid-" + uuid).remove();
    if (!data.basket.hasBonusProduct) {
        $(".bonus-product").remove();
    }

    var $promoCodeCsrfToken = $(".promo-code-form #promo-code-csrf-token");
    var promoCodeCsrfTokenName = $promoCodeCsrfToken.attr("name");
    var promoCodeCsrfTokenVal = $promoCodeCsrfToken.val();

    var $cartDiscountsHtml = $(data.basket.totals.discountsHtml);
    $cartDiscountsHtml.find("#coupon-csrf-token").attr("name", promoCodeCsrfTokenName).val(promoCodeCsrfTokenVal);
    $(".cart-discounts").html($cartDiscountsHtml);

    updateCartTotals(data.basket);
    updateApproachingDiscounts(data.basket.approachingDiscounts);
    $("body").trigger("setShippingMethodSelection", data.basket);
    validateBasket(data.basket);

    var isCheckoutPage = $("#checkout-main").length > 0;
    var isMiniCart = $(".minicart .popover").hasClass("show");

    if (!isMiniCart && ((data.basket && !data.basket.atLeastOneBudgetPay && isCheckoutPage) || (data.basket && !data.basket.shippingDiscountEligibleForUpsSh) || !($("#fedex-expedited-second-day").length > 0))) {
        window.location.reload();
    }
}

/**
 * Update classes on pdp to hide/show
 * sections
 * @param {object} isAvailable product availability
 */
function updateAvailabilityOnPDP(isAvailable, updateQty) {
    var $availableDnone = $(".available-d-none");
    var $notAvailableDnone = $(".not-available-d-none");
    var $addToCart = $(".add-to-cart-sticky");
    var $doNotShowBlock = $(".do-not-show-if-not-available-block");
    var $showBlock = $(".show-if-not-available-block");
    var $prices = $(".prices-show");
    var $outOfStockLabel = $(".outofstock-label");
    var $addToCartContainer = $(".add-to-cart-container");
    if (isAvailable) {
        $notAvailableDnone.removeClass("d-none");
        if (updateQty > 1) {
            $notAvailableDnone.removeClass("d-none");
            if (!$availableDnone.hasClass("d-none")) {
                $availableDnone.addClass("d-none");
            }
        }

        $addToCart.removeClass("d-md-none");
        if (!$showBlock.hasClass("d-none")) {
            $showBlock.addClass("d-none d-md-block");
        }
        $doNotShowBlock.removeClass("d-none d-md-block");
        $prices.removeClass("prices-out-of-stock");
        if (!$outOfStockLabel.hasClass("d-none")) {
            $outOfStockLabel.addClass("d-none");
        }
        $outOfStockLabel.removeClass("d-md-none");
        $addToCartContainer.removeClass("d-none");
    } else {
        if (updateQty <= 1) {
            if (!$notAvailableDnone.hasClass("d-none")) {
                $notAvailableDnone.addClass("d-none");
                $availableDnone.removeClass("d-none");
            }
        }
        $showBlock.removeClass("d-none d-sm-block");

        if (!$doNotShowBlock.hasClass("d-none")) {
            $doNotShowBlock.addClass("d-none d-md-block");
        }
        if (!$addToCartContainer.hasClass("d-none")) {
            $doNotShowBlock.addClass("d-none");
        }
        if (!$prices.hasClass("prices-out-of-stock")) {
            $prices.addClass("prices-out-of-stock");
        }
        if (!$outOfStockLabel.hasClass("d-md-none")) {
            $outOfStockLabel.addClass("d-md-none");
        }
        if (!$addToCart.hasClass("d-md-none")) {
            $addToCart.addClass("d-md-none");
        }
        $outOfStockLabel.removeClass("d-none");
    }
}

/**
 * Hadels error ajax response
 * after product removal
 * @param {object} err
 */
function handleRemoveError(err) {
    if (err.responseJSON.redirectUrl) {
        window.location.href = err.responseJSON.redirectUrl;
    } else {
        createErrorNotification(err.responseJSON.errorMessage);
        $.spinner().stop();
    }
}

var handleShippingMethods = () => {
    var countryControlContainer = $(".country-control");
    var checkedCountryOption = countryControlContainer.find($(".countrySelector:checked"));
    var checkedCountry = checkedCountryOption.attr("data-country-id");
    var customerCountry = checkedCountryOption.attr("data-customer-country");
    var customerAuthenticationStatus = checkedCountryOption.attr("data-customer-authenticationStatus");

    $(".option"+checkedCountry).show();
    $(document).on("change", ".countrySelector", function (event) {
        var selectedCountry = $(this).attr("data-country-id");
        $(".shippingItem").hide();
        $(".option"+selectedCountry).show();

        // guest customers can switch countries but we uncheck any checked shipping method
        //  in order not to end up with a shipping method for a different country
        if (customerAuthenticationStatus
            && customerAuthenticationStatus === "guest"
            && customerCountry
            && customerCountry != event.currentTarget.value
        ) {
            $(".shippingItem .shippingMethods").prop("checked", false);
        }

    });
};

var recommendationInit = () => {
    var $productCarousel = $(".product-carousel");
    if ($productCarousel.length) {
        $productCarousel.slick({
            dots: false,
            infinite: false,
            arrows: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
};

/**
 * Quantity checker for live tv products
 */
function checkLiveTvProductsQuantity() {
    var liveTvProductsInCart = $(".js-live-tv-item");

    if (liveTvProductsInCart.length > 0) {
        liveTvProductsInCart.each(function () {
            var currentProduct = $(this);
            var currentProductID = currentProduct.data("id");
            var currentStock = localStorage.getItem(currentProductID);
            var MAXQUANTITY = 10;
            if (currentProduct.data("maxorderqty") != undefined || currentProduct.data("maxorderqty") != null) {
                MAXQUANTITY = currentProduct.data("maxorderqty");
            }

            const isCheckout = $("#checkout-main").length > 0;

            if (currentStock) {
                var actualMaxQuantity = Math.min(currentStock, MAXQUANTITY);

                if (isCheckout) {
                    var currentQuantityInput = currentProduct.find(".quantity-input");
                    currentQuantityInput.attr("max", actualMaxQuantity);
                } else {
                    var currentQuantitySelector = currentProduct.find(".quantity");
                    var currentOptions = currentQuantitySelector.find("option");

                    currentOptions.each(function (index) {
                        var currentOption = $(this);
                        if (index > actualMaxQuantity - 1) {
                            currentOption.remove();
                        }
                    });
                }
            }
        });
    }
}

function handleTimer() {
    var $upsellWidget = $(".upsell-widget");
    var timeLeft = $upsellWidget.length ? parseInt($upsellWidget.data("timer-seconds"), 10) : 1799; // 30 minutes in seconds
    var timerInterval = setInterval(function () {
        if ($upsellWidget.length && $upsellWidget.hasClass("reset-timer")) {
            $upsellWidget.removeClass("reset-timer");
            clearInterval(timerInterval);
            handleTimer();
        }
        var minutes = Math.floor(timeLeft / 60);
        var seconds = timeLeft % 60;
        $(".timer-minutes").text(minutes);
        $(".timer-seconds").text((seconds < 10 ? "0" : "") + seconds);
        $(".timer-bar").css("transform", "translateX(" + ((1 - timeLeft / 1799) * 100) + "%)");
        if (timeLeft == 0) {
            clearInterval(timerInterval);

            if ($upsellWidget.length && !$upsellWidget.hasClass("offer-accepted")) {
                $("#upsellWidgetModal").modal("hide");
            }
        } else {
            timeLeft--;
        }
    }, 1000);
}

function protectionPlanEvents() {
    $(document).on("submit", ".protection-plan-form", function (e) {
        e.preventDefault();

        var $form = $(this);

        $.spinner().start();

        $.ajax({
            url: $form.attr("action"),
            type: "post",
            dataType: "json",
            data: $form.serialize(),
            success: function (res) {
                updateCartTotals(res);
                updateApproachingDiscounts(res.approachingDiscounts);
                validateBasket(res);
                if (res.freeShippingBanner) {
                    $(".js-free-shipping-banner-inner").empty().append(res.freeShippingBanner);
                    $(".js-free-shipping-banner").attr({
                        "data-basketamount": res.adjustedMerchandizeTotalPrice,
                        "data-approachinglimit": res.freeShippingPriceLimit,
                        "data-appliedFreeShipping": res.appliedFreeShipping,
                        "data-promofreeshipping": res.promoFreeShipping
                    });
                    dynamicApproachBanner();
                }

                $("body").trigger("cart:update");
                $("body").trigger("fpcCart:update");

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    });

    $(document).on("change", ".protection-plan-checkbox", function () {
        var $form = $(this).parents("form");

        $form.submit();
    });
}

module.exports = function () {
    handleTimer();
    checkLiveTvProductsQuantity();
    bidHistory();
    dynamicApproachBanner();
    handleShippingMethods();
    initPaypalButton();
    protectionPlanEvents();
    if ($(".cart-page").length > 0) {
        recommendationInit();
        $(".paypal-buttons-layout-vertical").first().remove();
    }
    var isBudgetPaySelected = $(".budgetPayCheckbox[value='selected']").is(":checked");
    if (isBudgetPaySelected) {
        $(".paypal-button-container").addClass("d-none");
    }

    $("body").on("click", ".remove-product", function (e) {
        e.preventDefault();

        var actionUrl = $(this).data("action");
        var productID = $(this).data("pid");
        var productName = $(this).data("name");
        var uuid = $(this).data("uuid");
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $("body").on("click", ".countrySelector", function () {
        var $poBox = $(".pobox-orders");

        if ($(this).data("country-id") === "CA") {
            $(".approaching-discount").addClass("d-none");

            if ($poBox) {
                $(".pobox-orders").addClass("d-none");
            }
        } else {
            $(".approaching-discount").removeClass("d-none");

            if ($poBox) {
                $(".pobox-orders").removeClass("d-none");
            }
        }
    });

    var $checkedShippingOption = $(".shippingItem input:checked");
    $("body").on("click", ".shippingItem", function () {
        if ($checkedShippingOption.length) {
            $(".shippingItem").addClass("transparent-background");
            $(this).closest(".shippingItem").removeClass("transparent-background");
        }
    });

    $("body").on("afterRemoveFromCart", function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $(".optional-promo").click(function (e) {
        e.preventDefault();
        $(".promo-code-form").toggle();
    });

    $("body").on("SFL:remove click", ".cart-delete-confirmation-btn", function (e) {
        e.preventDefault();
        var $this = $(this);
        var productID = $this.data("pid");
        var url = $this.data("action");
        var uuid = $this.data("uuid");
        var qty = $this.data("quantity");
        var price = parseFloat($this.data("price").replace(/[$,]/g, ""));
        var urlParams = {
            pid: productID,
            uuid: uuid
        };
        var dyForm = {
            pid: productID,
            quantity: parseInt($this.parents(".card").find(".line-item-quantity .quantity").val(), 10),
            price: price/qty
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $this.parents('.card').slideUp(333);

        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                localStorage.removeItem(productID);
                //gtm event fire
                gtmHelper.fireGtmEvent("REMOVE_FROM_CART", { ecomm_prodid: [$this.data("pid").toString()], value: parseFloat($this.data("price").replace("$", "")) });
                gaHelper.fireGAEvent("REMOVE_FROM_CART", { id: $this.data("pid").toString(), name: $this.data("name"), price: parseFloat($this.data("price").replace("$", "")), quantity: parseInt($this.data("quantity"), 10) });
                dyHelper.fireDYEvent("REMOVE_FROM_CART", dyForm);
                dyHelper.fireDYEvent("SPA_CART", data.basket.items);
                if (data.basket.items.length === 0) {
                    cleanBasket(data);
                } else {
                    updateCartValues(data, uuid);
                    if (data.freeShippingBanner) {
                        $(".js-free-shipping-banner-inner").empty().append(data.freeShippingBanner);
                        $(".js-free-shipping-banner").attr({
                            "data-basketamount": data.basket.adjustedMerchandizeTotalPrice,
                            "data-approachinglimit": data.freeShippingPriceLimit,
                            "data-appliedFreeShipping": data.appliedFreeShipping,
                            "data-promofreeshipping": data.promoFreeShipping
                        });
                        dynamicApproachBanner();
                    }
                    if (!data.basket.hasJewelryProducts) {
                        $(".container.recommendation-slot").remove();
                    }
                }

                $("body").trigger("cart:update");
                $("body").trigger("fpcCart:update");
                $('.minicart-placeholder').attr('data-placeholder', data.basket.items.length);
            },
            error: function (err) {
                $this.parents('.card').slideDown();
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: err.responseJSON.errorMessage
                });

                handleRemoveError(err);
            }
        });
    });

    $("body").on("click", ".checkout-delete-confirmation-btn", function (e) {
        e.preventDefault();
        var productID = this.dataset.pid;
        var url = $(this).data("action");
        var uuid = this.dataset.uuid;
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                updateCartValues(data, uuid);
                localStorage.removeItem(productID);
                if (data.basket.items.length === 0) {
                    if (data.basket.redirectToCartUrl) {
                        window.location.href = data.basket.redirectToCartUrl;
                    }
                }

                $("body").trigger("cart:update");
                $("body").trigger("fpcCart:update");

                $.spinner().stop();
            },
            error: function (err) {
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: err.responseJSON.errorMessage
                });

                handleRemoveError(err);
            }
        });
    });

    $("body").on("change click", ".quantity-form > .quantity", function (e) {
        if (this.localName === "select" && e.type === "click") {
            return;
        }
        var preSelectQty = $(this).data("pre-select-qty");
        var quantity = $(this).val();
        var productID = $(this).data("pid");
        var url = $(this).data("action");
        var uuid = $(this).data("uuid");
        var $item = $(".quantity-form .quantity-value-" + uuid);
        var checkoutQuantity = Number($item.val());

        if (checkoutQuantity) {
            quantity = checkoutQuantity;
        }

        var dyForm = {
            pid: productID,
            price: parseFloat($(this).parents(".card").find(".cart-delete-confirmation-btn").data("price").replace(/[$,]/g, ""))/$(this).parents(".card").find(".cart-delete-confirmation-btn").data("quantity")
        };
        if (parseInt(quantity, 10) > parseInt(preSelectQty, 10)) {
            dyForm.quantity = parseInt(quantity, 10) - parseInt(preSelectQty, 10);
            dyHelper.fireDYEvent("ADD_TO_CART", dyForm);
        } else {
            dyForm.quantity = parseInt(preSelectQty, 10) - parseInt(quantity, 10);
            dyHelper.fireDYEvent("REMOVE_FROM_CART", dyForm);
        }

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        var gaForm = {
            id: productID,
            quantity: quantity
        };
        url = appendToUrl(url, urlParams);

        $(this).parents(".card").spinner().start();

        var $promoCodeCsrfToken = $(".promo-code-form #promo-code-csrf-token");
        var promoCodeCsrfTokenName = $promoCodeCsrfToken.attr("name");
        var promoCodeCsrfTokenVal = $promoCodeCsrfToken.val();

        $.ajax({
            url: url,
            type: "get",
            context: this,
            dataType: "json",
            success: function (data) {
                if (data && data.items) {
                    data.items.forEach(item => {
                        if (item.id && item.quantity) {
                            $(`[data-id=${item.id}]`).find(".quantity").val(parseInt(item.quantity, 10));
                        }

                        if (item.hasWarranty && $(".protection-plan-" + item.UUID).length) {
                            $(".protection-plan-" + item.UUID).find("strong").html(item.warrantyTotalPrice);
                        }
                    });
                }
                var $cartDiscountsHtml = $(data.totals.discountsHtml);
                $cartDiscountsHtml.find("#coupon-csrf-token").attr("name", promoCodeCsrfTokenName).val(promoCodeCsrfTokenVal);
                $(".cart-discounts").html($cartDiscountsHtml);

                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                if (data.freeShippingBanner) {
                    $(".js-free-shipping-banner-inner").empty().append(data.freeShippingBanner);
                    $(".js-free-shipping-banner").attr({
                        "data-basketamount": data.adjustedMerchandizeTotalPrice,
                        "data-approachinglimit": data.freeShippingPriceLimit,
                        "data-appliedFreeShipping": data.appliedFreeShipping,
                        "data-promofreeshipping": data.promoFreeShipping
                    });
                    dynamicApproachBanner();
                }
                $(this).data("pre-select-qty", quantity);

                $("body").trigger("cart:update");
                $("body").trigger("fpcCart:update");

                $.spinner().stop();
                if ($(this).parents(".product-info").hasClass("bonus-product-line-item") && $(".cart-page").length) {
                    location.reload();
                }
                $(".quantity-form > .quantity").blur();
                gaHelper.fireGAEvent("UPDATE_QTY_CART", gaForm);
                //window.location.reload();
                if (data.freeGuestCartShow) {
                    $(".promotion-information").hide();
                } else {
                    $(".promotion-information").show();
                }
            },
            error: function (err) {
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: err.responseJSON.errorMessage
                });

                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $(".shippingMethods").change(function () {
        var url = $(this).attr("data-actionUrl");
        var urlParams = {
            methodID: $(this).attr("data-shipping-id")
        };
        var gaFormData = {
            id: $(this).data("shipping-id"),
            name: $(this).data("shm-name")
        };

        if (url) {
            $(".totals").spinner().start();
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: urlParams,
                success: function (data) {
                    if (data.error) {
                        window.location.href = data.redirectUrl;
                    } else {
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        $("input[name='dwfrm_shipping_shippingAddress_shippingMethodID']").each(function () {
                            $(this).prop("checked", false);
                            if ($(this).attr("id").includes(data.shipments[0].selectedShippingMethod)) {
                                $(this).click();
                            }
                        });
                        validateBasket(data);
                    }
                    $.spinner().stop();
                    gaHelper.fireGAEvent("SHM_UPDATE", gaFormData);
                },
                error: function (err) {
                    gtmHelper.fireGtmEvent("Error", {
                        errorMessage: err.responseJSON.errorMessage
                    });

                    if (err.redirectUrl) {
                        window.location.href = err.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(".promo-code-form").submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $(".coupon-missing-error").hide();
        $(".coupon-error-message").text();
        if ($(this).find(".coupon-code-field").val() == "") {
            $(this).find(".form-control").addClass("is-invalid");
            $(this).find(".form-control").attr("aria-describedby", "missingCouponCode");
            $(".coupon-missing-error").show();
            $.spinner().stop();
            return;
        }
        var $form = $(this);
        var $promoCodeCsrfToken = $form.find("#promo-code-csrf-token");
        var promoCodeCsrfTokenName = $promoCodeCsrfToken.attr("name");
        var promoCodeCsrfTokenVal = $promoCodeCsrfToken.val();

        $(".promo-code-form .form-control").removeClass("is-invalid");
        $(".coupon-error-message").empty();

        $.ajax({
            url: $form.attr("action"),
            type: "GET",
            dataType: "json",
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $(".promo-code-form .form-control").addClass("is-invalid");
                    $(".promo-code-form .form-control").attr("aria-describedby", "invalidCouponCode");
                    $(".coupon-error-message").empty().append(data.errorMessage);
                    $("body").trigger("promotion:error", data);

                    gtmHelper.fireGtmEvent("Error", {
                        errorMessage: data.errorMessage
                    });
                } else {
                    $(".promo-code-form").addClass("d-none");
                    var $cartDiscountsHtml = $(data.totals.discountsHtml);
                    $cartDiscountsHtml.find("#coupon-csrf-token").attr("name", promoCodeCsrfTokenName).val(promoCodeCsrfTokenVal);
                    $(".cart-discounts").html($cartDiscountsHtml);
                    $(".coupon-code-field").addClass("coupon-success");

                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    if (data.freeShippingBanner) {
                        $(".js-free-shipping-banner-inner").html(data.freeShippingBanner);
                        $(".js-free-shipping-banner").attr({
                            "data-basketamount": data.adjustedMerchandizeTotalPrice,
                            "data-approachinglimit": data.freeShippingPriceLimit,
                            "data-appliedFreeShipping": data.appliedFreeShipping,
                            "data-promofreeshipping": data.promoFreeShipping
                        });
                        dynamicApproachBanner();
                    }
                    $("body").trigger("promotion:success", data);

                    var gtmData = {
                        discountValue: Number(data.totals.totalDiscounts.replace('-$','')),
                        couponCode: data.totals.discounts[0].couponCode,
                        isManuallyAdded: true
                    }
                    gtmHelper.fireGtmEvent("DISCOUNT_APPLIED", gtmData);
                    if (data.freeGuestCartShow) {
                        $(".promotion-information").hide();
                    } else {
                        $(".promotion-information").show();
                    }
                }
                $(".coupon-code-field").val("");
                $.spinner().stop();
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: err.responseJSON.errorMessage
                });

                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $("body").on("click", ".js-remove-coupon", function (e) {
        e.preventDefault();

        var couponCode = $(this).data("code");
        var uuid = $(this).data("uuid");
        var $deleteConfirmBtn = $(".delete-coupon-confirmation-btn");
        var $productToRemoveSpan = $(".coupon-to-remove");

        $deleteConfirmBtn.data("uuid", uuid);
        $deleteConfirmBtn.data("code", couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $("body").on("click", ".delete-coupon-confirmation-btn", function (e) {
        e.preventDefault();

        var $couponsAndPromos = $(this).closest(".coupons-and-promos");
        var $promoCodeForm = $(".promo-code-form");
        var $discountFields = $(".discounted-fields");

        $discountFields.addClass("d-none");
        $promoCodeForm.removeClass("d-none");

        var url = $couponsAndPromos.attr("data-remove-action");
        var uuid = $(this).attr("data-uuid");
        var couponCode = $(this).attr("data-code");
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        $(".coupon-code-field").removeClass("coupon-success");

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                $(".coupon-uuid-" + uuid).remove();
                if (data.totals && data.totals.discountsHtml) {
                    var $cartDiscountsHtml = $(data.totals.discountsHtml);
                    $(".cart-discounts").html($cartDiscountsHtml);
                }
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                if (data.freeShippingBanner) {
                    $(".js-free-shipping-banner-inner").html(data.freeShippingBanner);
                    $(".js-free-shipping-banner").attr({
                        "data-basketamount": data.adjustedMerchandizeTotalPrice,
                        "data-approachinglimit": data.freeShippingPriceLimit,
                        "data-appliedFreeShipping": data.appliedFreeShipping,
                        "data-promofreeshipping": data.promoFreeShipping
                    });
                    dynamicApproachBanner();
                }

                data.items.forEach(function (item) {
                    if (data.hasBonusProduct) {
                        $(".order-discount-total").empty()
                            .append(data.totals.totalDiscounts);
                        $(".order-discount-label").addClass("red");
                        $(".order-discount-total").addClass("red");
                    }
                    var bonusMessage = $(".free-gift-product").attr("data-message");
                    $(".free-gift-product .product-discount").empty().html("<i class=\"fa fa-check-circle tick\" ></i> " + "<b>" + bonusMessage + "</b>");
                    //free shipping
                    if (item.freeShipping) {
                        $(".d-free-shipping-discount-" + item.UUID).empty().html("<i class=\"fa fa-check-circle tick\"></i>" +
                        "<b> Free Shipping Applied!</b>");
                    }
                    else {
                        $(".free-gift-product .product-discount").empty().html("<i class=\"fa fa-check-circle tick\" ></i> " + "<b>" + bonusMessage + "</b>");
                    }
                });

                if (data.freeGuestCartShow) {
                    $(".promotion-information").hide();
                } else {
                    $(".promotion-information").show();
                }

                $.spinner().stop();
                $("body").trigger("promotion:success", data);
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: err.responseJSON.errorMessage
                });

                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $("body").on("click", ".cart-page .bonus-product-button", function () {
        $.spinner().start();
        $(this).addClass("launched-modal");
        $.ajax({
            url: $(this).data("url"),
            method: "GET",
            dataType: "json",
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $("body").on("hidden.bs.modal", "#chooseBonusProductModal", function () {
        $("#chooseBonusProductModal").remove();
        $(".modal-backdrop").remove();
        $("body").removeClass("modal-open");

        if ($(".cart-page").length) {
            $(".launched-modal .btn-outline-primary").trigger("focus");
            $(".launched-modal").removeClass("launched-modal");
        } else {
            $(".product-detail .add-to-cart").focus();
        }
    });

    $("body").on("click", ".cart-page .product-edit .edit, .cart-page .bundle-edit .edit", function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr("href");
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $("body").on("shown.bs.modal", "#editProductModal", function () {
        $("#editProductModal").siblings().attr("aria-hidden", "true");
        $("#editProductModal .close").focus();
    });

    $("body").on("hidden.bs.modal", "#editProductModal", function () {
        $("#editProductModal").siblings().attr("aria-hidden", "false");
    });

    $("body").on("keydown", "#editProductModal", function (e) {
        var focusParams = {
            event: e,
            containerSelector: "#editProductModal",
            firstElementSelector: ".close",
            lastElementSelector: ".update-cart-product-global",
            nextToLastElementSelector: ".modal-footer .quantity-select"
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $("body").on("product:updateAddToCart", function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest(".quick-view-dialog");

        $(".update-cart-product-global", dialog).attr("disabled",
            !$(".global-availability", dialog).data("ready-to-order")
            || !$(".global-availability", dialog).data("available")
        );
    });

    $("body").on("product:updateAvailability", function (e, response) {
        // bundle individual products
        if ($(".cart-page").length > 0) {
            $(".product-availability", response.$productContainer)
                .data("ready-to-order", response.product.readyToOrder)
                .data("available", response.product.available)
                .find(".availability-msg")
                .empty()
                .html(response.message);
        }


        var dialog = $(response.$productContainer)
            .closest(".quick-view-dialog");

        if ($(".product-availability", dialog).length) {
            // bundle all products
            var allAvailable = $(".product-availability", dialog).toArray()
                .every(function (item) { return $(item).data("available"); });

            var allReady = $(".product-availability", dialog).toArray()
                .every(function (item) { return $(item).data("ready-to-order"); });

            $(".global-availability", dialog)
                .data("ready-to-order", allReady)
                .data("available", allAvailable);

            $(".global-availability .availability-msg", dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $(".global-availability", dialog)
                .data("ready-to-order", response.product.readyToOrder)
                .data("available", response.product.available)
                .find(".availability-msg")
                .empty()
                .html(response.message);
        }
    });

    $("body").on("product:afterAttributeSelect", function (e, response) {
        updateAvailabilityOnPDP(response.data.product.available, response.data.product.selectedQuantity);
        if ($(".modal.show .product-quickview .bundle-items").length) {
            $(".modal.show").find(response.container).data("pid", response.data.product.id);
            $(".modal.show").find(response.container).find(".product-id").text(response.data.product.id);
        } else {
            $(".modal.show .product-quickview").data("pid", response.data.product.id);
        }
    });

    $("body").on("change", ".quantity-select", function () {
        var selectedQuantity = $(this).val();
        $(".modal.show .update-cart-url").data("selected-quantity", selectedQuantity);
    });

    $("body").on("change", ".options-select", function () {
        var selectedOptionValueId = $(this).children("option:selected").data("value-id");
        $(".modal.show .update-cart-url").data("selected-option", selectedOptionValueId);
    });

    $("body").on("click", ".update-cart-product-global", function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest(".cart-and-ipay").find(".update-cart-url").val();
        var selectedQuantity = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-quantity");
        var selectedOptionValueId = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-option");
        var uuid = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("uuid");

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        var $promoCodeCsrfToken = $(".promo-code-form #promo-code-csrf-token");
        var promoCodeCsrfTokenName = $promoCodeCsrfToken.attr("name");
        var promoCodeCsrfTokenVal = $promoCodeCsrfToken.val();

        $(this).parents(".card").spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: "post",
                context: this,
                data: form,
                dataType: "json",
                success: function (data) {
                    $("#editProductModal").modal("hide");

                    var $cartDiscountsHtml = $(data.cartModel.totals.discountsHtml);
                    $cartDiscountsHtml.find("#coupon-csrf-token").attr("name", promoCodeCsrfTokenName).val(promoCodeCsrfTokenVal);
                    $(".cart-discounts").html($cartDiscountsHtml);

                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(".uuid-" + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $("body").trigger("cart:update");
                    $("body").trigger("fpcCart:update");

                    $.spinner().stop();
                },
                error: function (err) {
                    gtmHelper.fireGtmEvent("Error", {
                        errorMessage: err.responseJSON.errorMessage
                    });

                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(document).on("bugetpay:trigger", function (event) {
        var url;
        if (event.target.options !== undefined) {
            url = event.target.options[event.target.options.selectedIndex].dataset.url;
        } else {
            url = event.target.dataset.url;
        }

        if ($(".cart-page").length > 0) {
            var $storeCreditForm = $(".store-credit-form");
            var csrfTokenName = $storeCreditForm.find("input#store-credit-csrf-token").attr("name");
            var csrfTokenVal = $storeCreditForm.find("input#store-credit-csrf-token").val();
        }

        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                if (data.atLeastOneBudgetPay || data.allBudgetItems) {
                    var $allBudgetpayMsg = $(".all-budgetpay-msg");
                    var $mixedBudgetPayMsg = $(".mixed-budgetpay-msg");
                    var customerAuthenticated = $(".password-mandatory-message").data("authenticatedcustomer");
                    $(".budgetpay-message").removeClass("d-none");
                    if (!customerAuthenticated) {
                        $(".password-mandatory-message").removeClass("d-none");
                    }
                    $("#full-payable-amount").empty().append(data.fullPayableAmount);
                    $(".next-step-buttons-container .price-total").text("$" + data.fullPayableAmount);
                    $("#payableAmount").empty().append(data.fullPayableAmount);
                    $((".create-account-field-wrapper")).find(".form-group").addClass("required");

                    if ($("#checkout-main").data("customer-type") === "guest" || $(".payment-options-loggedin").length === 0) {
                        $(".nav-link.credit-card-tab").tab("show");
                        $(".js-nav-item.js-bp-restricted").addClass("d-none");
                    } else {
                        $("input[type='radio'].js-bp-restricted").prop("checked", false);
                        $("input[type='radio'].js-bp-restricted").parents(".js-bp-restricted").addClass("d-none");

                        if ($("input[type='radio'].cc-radio").length) {
                            $("input[type='radio'].cc-radio").prop("checked", true);
                            $("input[type='radio'].cc-radio").trigger("change");
                        }
                    }

                    $(".before-placeOrder").removeClass("d-none");
                    $(".js-external-btn-wrapper").addClass("d-none");

                    if (data.allBudgetItems) {
                        $allBudgetpayMsg.removeClass("d-none");
                        $mixedBudgetPayMsg.addClass("d-none");
                    } else {
                        $mixedBudgetPayMsg.removeClass("d-none");
                        $allBudgetpayMsg.addClass("d-none");
                    }

                    $(document).on("click", ".cart-delete-confirmation-btn", function () {
                        if (!data.allBudgetItems) {
                            $allBudgetpayMsg.removeClass("d-none");
                            $mixedBudgetPayMsg.addClass("d-none");
                        }
                    });
                } else {
                    $(".budgetpay-message").addClass("d-none");
                    $(".password-mandatory-message").addClass("d-none");
                    $((".create-account-field-wrapper")).find(".form-group").removeClass("required");
                    $(".js-nav-item.js-bp-restricted").removeClass("d-none");
                    $(".tab-pane.js-bp-restricted").removeClass("d-none");
                    $(".js-external-btn-wrapper").removeClass("d-none");
                    $(".next-step-buttons-container .price-total").text(data.totals.grandTotal);
                }

                if ($(".cart-page").length > 0) {
                    var $html = $(data.storeCreditHtmlContent);
                    $html.find("input#store-credit-csrf-token").attr("name", csrfTokenName);
                    $html.find("input#store-credit-csrf-token").val(csrfTokenVal);

                    $(".cart-store-credit").html($html);

                    updateCartTotals(data);
                }
                if ($(".product-item-wrapper").length > 0) {
                    updateCartTotals(data);
                }
            }
        });
    });

    $(".js-bp-mobile-select").on("change", function () {
        $(this).trigger("bugetpay:trigger");
    });

    $(".popover, .budget-form").on("click", ".budgetPayCheckbox", function (e) {
        var isBudgetPaySelected = $(e.currentTarget).val();
        if (isBudgetPaySelected == "notSelected") {
            $(".paypal-button-container").removeClass("d-none");
        } else {
            $(".paypal-button-container").addClass("d-none");
        }
        var uuid = $(this).data("uuid");
        $(this).trigger("bugetpay:trigger");
        if ($("#checkout-main").data("customer-type") === "guest" || $(".payment-options-loggedin").length === 0) {
            $(".js-nav-item .credit-card-tab").click();
        }

        var updateurl = $((".product-item-wrapper")).find(".budgetPayCheckbox").data("updateurl");
        $("#bpInstallmentMessage-"+uuid).load(updateurl+ (" #bpInstallmentMessage-"+uuid));
    });

    $(document).on("submit", ".store-credit-form", function (e) {
        e.preventDefault();
        var $form = $(this);
        var csrfTokenName = $form.find("input#store-credit-csrf-token").attr("name");
        var csrfTokenVal = $form.find("input#store-credit-csrf-token").val();

        $.ajax({
            url: $form.attr("action"),
            type: "POST",
            dataType: "json",
            data: $form.serialize(),
            success: function (data) {
                var $html = $(data.storeCreditHtmlContent);
                $html.find("input#store-credit-csrf-token").attr("name", csrfTokenName);
                $html.find("input#store-credit-csrf-token").val(csrfTokenVal);

                $(".cart-store-credit").html($html);
                clientSideValidation.invalid();

                updateCartTotals(data);
            },
            error: function (err) {
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: err.responseJSON.errorMessage
                });

                $form.find("input.store-credit-input").addClass("is-invalid");
                $form.find(".invalid-feedback").text(err.responseJSON.errorMessage).show();
            }
        });
    });

    // download personalize preview image
    $("body").on("click", "#c-save", function (e) {
        e.preventDefault();
        var url = $("#customilyModalPreview").find("#pre-img").attr("src");
        util.downloadImage(url, "Preview.png");
    });

    // preview personalization
    $("body").on("click", ".personalize-msg .preview-img", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        $("#customilyModalPreview").find("#pre-img").attr("src", url);
        $("#customilyModalPreview").modal("show");
    });
    // recommendations add to cart event
    $(".card.product-info").on("mouseover", function () {
        $(this).find(".hover-section").show();
    });

    $(".card.product-info, .dy-product-tile").on("mouseout", function () {
        $(this).find(".hover-section").hide();
    });

    $(document).on("mouseover", ".dy-product-tile", function () {
        $(this).find(".hover-section").show();
    });
    $(document).on("mouseout", ".dy-product-tile", function () {
        $(this).find(".hover-section").hide();
    });

    $("body").on("click", ".cart-page .dy-upsell-add-to-cart, .cart-empty .dy-upsell-add-to-cart", function () {
        var pid = $(this).data("pid");
        var addToCartUrl = $(this).data("url");

        var price;
        if ($(this).closest(".product-info").find(".js-formatted-price").html().includes("&nbsp;$")) {
            price = $(this).closest(".product-info").find(".js-formatted-price").html().replace("&nbsp;$", "");
        } else {
            price = $(this).closest(".product-info").find(".sales .js-formatted-price").html().trim().replace(/[$,]/g, "");
        }

        var dyForm = {
            pid: pid,
            quantity: 1,
            price: price
        };

        var form = {
            pid: pid,
            quantity: 1
        };

        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: "POST",
                data: form,
                success: function (data) {
                    handlePostCartAdd(data);
                    $("body").trigger("fpcProduct:afterAddToCart", data);
                    base.miniCartReportingUrl(data.reportingURL, data.error);
                    dyHelper.fireDYEvent("ADD_TO_CART", dyForm);
                    $.spinner().stop();

                    location.reload();
                },
                error: function (data) {
                    $.spinner().stop();
                    var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                    if (modal) {
                        $(modal.modalId).modal("show");
                    }
                }
            });
        }
    });

    $(".warranty-popover").popover({
        trigger: "click hover",
        html: true,
        placement: "left"
    });

    $(function (){
        //gtm event fire
        gtmHelper.fireGtmEvent("CART");
        gaHelper.fireGAEvent("CHECKOUT");
    })

    base.selectAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
